// src/GuideCard.js

import React from 'react';
import '../../Assets/Styles/GuideCard.css'; // Import CSS for styling


const PeapleGuide = () => {
  return (
    <div className="guide-card">
      <h2>Welcome to People Count Feature</h2>
      <p>Our People Count feature offers robust capabilities for accurate monitoring:</p>
      
      <h3>1. People Count</h3>
      <p>
        This feature counts the number of people detected within a specified area. It includes:
      </p>
      <ul>
        <li> <strong>Accurate Counting:</strong> Counts individuals accurately based on their presence within the defined monitoring zone.</li>
        <li> <strong>Real-time Updates:</strong> Provides continuous updates on the number of people present, suitable for various applications like crowd management and occupancy monitoring.</li>
      </ul>
      
      <h3>2. All Objects Count</h3>
      <p>
        In addition to people, our system counts all objects within the specified area. Key features include:
      </p>
      <ul>
        <li><strong> Comprehensive Monitoring: </strong> Tracks and counts all objects detected, providing insights into activity and occupancy.</li>
        <li> <strong>Object Classification:</strong> Differentiates between people and other objects, enhancing the specificity of monitoring.</li>
      </ul>
      
      <p>
        Utilize the People Count feature to optimize operations and enhance security in your environment.
      </p>
    </div>
  );
};

export default PeapleGuide;
