import React, { useEffect } from "react";
import "../../Assets/Styles/OurService.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

import FeatureCard from "./FeatureCard";
import featureData from  '../../Components/Homepage/ServiceData'

const OurService = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: 'ease-in-out', 
      once: true, 
    });
  }, []);

  return (
    <div className="feature-sections">
      <div className="feature__container">
      {featureData.map((feature, index) => (
        <div
          key={index}
          data-aos={index % 2 === 0 ? "fade-right" : "fade-up"} 
          className="featureCard__container"
        >
          <FeatureCard
            imgSrc={feature.imgSrc}
            altText={feature.altText}
            title={feature.title}
            text={feature.text}
            isTextLeft={index % 2 === 0} 
          />
        </div>
      ))}

      </div>
    </div>
  );
};

export default OurService;
