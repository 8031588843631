import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Importing styles

const DateTimePicker = ({ startDate, endDate, handleStartTimeChange, handleEndTimeChange }) => {
  return (
    <div className="dateTime">
      <div>
        <label>Start Time:</label>
        <DatePicker
          selected={startDate}
          onChange={handleStartTimeChange}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={2}
          timeCaption="Time"
          dateFormat="d MMMM , yyyy h:mm aa"
          className="selectUser"
        />
      </div>
      <div>
        <label className="endtimeLabel">End Time:</label>
        <DatePicker
          selected={endDate}
          onChange={handleEndTimeChange}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={2}
          timeCaption="Time"
          dateFormat=" d MMMM, yyyy h:mm aa"
          className="selectUser"
        />
      </div>
    </div>
  );
};

export default DateTimePicker;
