import React, { useContext, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { UserContext } from '../../ContextApi/MyContext';

const DeviceReport = () => {
    const { deviceData, GetDeviceData } = useContext(UserContext);

    useEffect(() => {
        GetDeviceData();
    }, []);

    return (
        <div className ='device-report'>
            <h1>Device Report</h1>
            <BarChart width={800} height={400} data={deviceData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="device_name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="device_id" fill="#8884d8" />
                {/* Add more bars for other data points */}
            </BarChart>
        </div>
    );
}

export default DeviceReport;
