import React, { useEffect } from "react";
import "../../Assets/Styles/AboutUs.css";
import ContentImg from "../../Assets/Images/TeamMember.png";
import CourageImg from "../../Assets/Images/Courage.png";
import SecurityImg from "../../Assets/Images/Security.png";
import ReliabilityImg from "../../Assets/Images/Reliability.png";
import DiamondImg from "../../Assets/Images/Diamond.png";
import CommitmentImg from "../../Assets/Images/Commitment.png";
import ServiceCard from "../../Components/Homepage/ServiceCard";
import img1 from "../../Assets/Images/TeamWork.png";
import img2 from "../../Assets/Images//Excellence.png";
import img3 from "../../Assets/Images/Integtrity.png";
import img4 from "../../Assets/Images/MutualRespect.png";
// import TeamWork from '../../Assets/Images/TeamWork.png'
// import Excellence from '../../Assets/Images/Excellence.png'
// import Integrity from '../../Assets/Images/Integtrity.png'
// import MutualRespect from '../../Assets/Images/MutualRespect.png'
const AboutUs = () => {
  const CardData = [
    {
      id: 1,
      image: img1,
    },
    {
      id: 2,
      image: img2,
    },
    {
      id: 3,
      image: img3,
    },
    {
      id: 4,
      image: img4,
    },
  ];

  return (
    <div className="aboutus">
      <div className="aboutus__container">
      <div className="aboutus__div1">
        <h1 className="div1__heading">Amazing Team For Amazing Support</h1>
        <div className="aboutus__image">
          <img src={ContentImg} alt="Logo" className="ContentImg" />
          <div className="aboutus__content">
            <p className="aboutus__p">
              Greetings from our AI Solutions hub, where intellect and
              creativity come together to build business transformation.
              <br />
              Our cutting-edge AI solutions improve efficiency across
              industries, accelerate decision-making, and streamline processes.
              <br />
              Our AI technologies are customized to meet your objectives,
              whether you're using them to personalize consumer interactions,
              automate activities using intelligent algorithms, or leverage
              machine learning for predictive insights.
              <br />
              Our dedication to providing secure and scalable solutions enables
              your company to experience exceptional expansion and prosperity.
              <br />
              Visit our website to see how our AI know-how can transform your
              business and give you a competitive edge.
            </p>
          </div>
        </div>
        <div className="div1__heading2">
          <h2>Our Value</h2>
        </div>
      </div>
      </div>
      <div className="aboutus__div2">
        {CardData.map((item, index) => (
          <ServiceCard  item={item} />
        ))}
      </div>
      <div className="aboutus__div3">
        <div className="aboutus__div--container" id="">
        <h1 className="div3__heading">Intriguing facts about BuyAiSolution </h1>
        <div className="iconNamesGrid">
          <div className="iconItem">
            <img src={CourageImg} alt="Courage" className="iconImage" />
            <p className="iconName">Courage</p>
          </div>
          <div className="iconItem">
            <img src={SecurityImg} alt="Security" className="iconImage" />
            <p className="iconName">Security</p>
          </div>
          <div className="iconItem">
            <img src={ReliabilityImg} alt="Reliability" className="iconImage" />
            <p className="iconName">Reliability</p>
          </div>
          <div className="iconItem">
            <img src={DiamondImg} alt="Diamond" className="iconImage" />
            <p className="iconName">Quality Product</p>
          </div>
          <div className="iconItem">
            <img src={CommitmentImg} alt="Commitment" className="iconImage" />
            <p className="iconName">Commitment</p>
          </div>
        </div>

        </div>
      </div>
    </div>
  );
};
export default AboutUs;
