import React, { useContext, useState } from "react";
import "../../Assets/Styles/table.css";
import { GrClose } from "react-icons/gr";
import { UserContext } from "../../ContextApi/MyContext";
import api from "./api";



const AddAlertModal = ({ closeModal,subtitleRef, fetchAlertUser, SuccessAlert, ErrorAlert ,clientData }) => {
  const [newUser, setNewUser] = useState({
    userName: "",
    userEmail: "",
    userContact: "",
  });
  const {  theme } = useContext(UserContext);

  const modalClassName =
    theme === "dark" ? "add-table dark" : "add-table light";


  const clientName = clientData ? clientData.clientName : null;

  
const handleAddUser = () => {
  api
   .post(`api/devices/${clientName}/alertuser`, {
      userName: newUser.user_name,
      userEmail: newUser.user_email,
      userContact: newUser.user_contact,
    })
   .then((response) => {
      closeModal();
      fetchAlertUser();
      SuccessAlert(response.data.message);
    })
   .catch((error) => {
      console.error("Error adding user:", error);

      if (error.response) {
        ErrorAlert(error.response.data.message || error.message);
      } else if (error.request) {
        ErrorAlert("No response received from server.", "Please try again later.");
      } else {
        ErrorAlert("An unexpected error occurred.", error.message);
      }
    });
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className={modalClassName}>
      <h4>Add User Sms Alert</h4>
      <button className="modal-closebtn" onClick={closeModal}>
        <GrClose />
      </button>
      <div className="addform">
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="user_name">
            User Name*
          </label>
          <input
            type="text"
            name="user_name"
            value={newUser.user_name || ""}
            className="inputs"
            placeholder="user Name"
            onChange={handleChange}
            required
          />
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="user_email">
            User email*
          </label>
          <input
            type="text"
            name="user_email"
            value={newUser.user_email || ""}
            className="inputs"
            placeholder="User Email"
            onChange={handleChange}
            required
          />
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="user_contact">
            User Whatsapp/Contact*
          </label>
          <input
            type="text"
            name="user_contact"
            value={newUser.user_contact || ""}
            className="inputs"
            placeholder="User Contact"
            onChange={handleChange}
            required
          />
        </div>
        <button className="submit-btn"  onClick={handleAddUser}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddAlertModal;
