import React, { useContext, useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import "../../Assets/Styles/addForm.css";
import { UserContext } from "../../ContextApi/MyContext";
import useFeatureExists from "../CustomHooks/useCheckFeture";
import api from "../Utils/api";

const AddTable = ({ closeModal, subtitleRef }) => {
 
  const { theme } = useContext(UserContext);
  const { SuccessAlert, ErrorAlert, GetDeviceData, clientData, deviceData ,clientfeatures} = useContext(UserContext);
  const [rtspUrl, setRtspUrl] = useState("");
  const [isUrlGenerated, setIsUrlGenerated] = useState(false);
  const { checkFeatureExists } = useFeatureExists(clientfeatures);
  const hasAnprFeature = checkFeatureExists('anpr');
  const hasObjectFeature = checkFeatureExists('objectcount');
  const hasSmartFeature  = checkFeatureExists('smartbox');
  // const [clientName, setClientName] = useState("")
  const [inputs, setInputs] = useState({
    device_id: "",
    device_name: "",
    password: "",
    device_type: "",
    ip: "",
    port: "",
    suffix: "",
    gate_type:""
  });
  const baseUrl = process.env.REACT_APP_API_BASE_URL

  const clientName = clientData ? clientData.clientName : null;
  console.log("Client Name:", clientName);

  const modalClassName =
    theme === "dark" ? "add-table dark" : "add-table light";

  const handleUrlGeneration = () => {
    const rtspUrl = `rtsp://${inputs.user_name}:${inputs.password}@${inputs.ip}:${inputs.port}/${inputs.suffix}/${inputs.device_id}`;
    setRtspUrl(rtspUrl);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  function configDevice() {
    // const client = clientData
    api
      .post(`/api/devices/${clientName}`, {
        device_id: inputs.device_id,
        device_name: inputs.device_name,
        user_name:inputs.user_name,
        password: inputs.password,
        device_type: inputs.device_type,
        ip: inputs.ip,
        port: inputs.port,
        suffix: inputs.suffix,
        url: rtspUrl,
        gate_type:inputs.gate_type
      })
      .then((response) => {
        console.log(response.data.message);
        SuccessAlert(response.data.message);

        // sentWebhookData()
        closeModal();
        GetDeviceData();
      })
      .catch((error) => {
        console.log(error.response.data.error);
        ErrorAlert(error.response.data.error);
      });
  }


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      !inputs.device_name ||
      !inputs.user_name ||
      !inputs.password ||
      !inputs.device_type ||
      !inputs.device_id ||
      !inputs.ip ||
      !inputs.port ||
      !inputs.suffix ||
      !inputs.gate_type
    ) {
      ErrorAlert("Please fill out all required fields.");
      return;
    }
    setIsUrlGenerated(true);
    configDevice();
  };

  return (
    <div className={modalClassName}>
      <div className="modal-title">
        <h4>Add Device</h4>
        <button className="modal-closebtn" onClick={closeModal}>
          <GrClose />
        </button>
      </div>

      <form onSubmit={handleSubmit} className="addform">
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="device_name">
            Device Name*
          </label>
          <input
            type="text"
            name="device_name"
            value={inputs.device_name || ""}
            className="inputs"
            placeholder="Device Name"
            onChange={handleInputChange}
          />
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="user_name">
            User Name
          </label>
          <input
            type="text"
            name="user_name"
            value={inputs.user_name || ""}
            className="inputs"
            placeholder="User Name"
            onChange={handleInputChange}
          />
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="password">
            Password*
          </label>
          <input
            type="password"
            name="password"
            value={inputs.password || ""}
            className="inputs"
            placeholder="Device Password"
            onChange={handleInputChange}
          />
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="device_type">
            Select Service*
          </label>
          <select
            onChange={handleInputChange}
            className="inputs"
            value={inputs.device_type}
            name="device_type"
          >
            <option className="inputs" value={null}>
              Select Services*
            </option>
            {hasObjectFeature  && <option className="inputs" value="objectcount">
              Object Count
            </option>}
            {hasAnprFeature && <option className="inputs" value="anpr">
              Anpr(Automatic number-plate recognition)
            </option>}
            {hasSmartFeature && <option className="inputs" value="smartbox">
              SmartBox Detection
            </option>}
          </select>
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="device_type">
            |Select Gate Type*
          </label>
          <select
            onChange={handleInputChange}
            className="inputs"
            value={inputs.gate_type}
            name="gate_type"
          >
            <option className="inputs" value={null}>
              Select Gate*
            </option>
            {hasSmartFeature && <option className="inputs" value="in">
              In
            </option>}
            {hasSmartFeature && <option className="inputs" value="out">
              Out
            </option>}
          </select>
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="device_id">
            Device Id*
          </label>
          <input
            type="number"
            name="device_id"
            value={inputs.device_id || ""}
            className="inputs"
            placeholder="Device Id"
            onChange={handleInputChange}
          />
        </div>

        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="ip">
            Device Ip*
          </label>
          <input
            type="number "
            name="ip"
            value={inputs.ip || ""}
            className="inputs"
            placeholder="eg:192.168.34.1"
            onChange={handleInputChange}
          />
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="port">
            Port*
          </label>
          <input
            type="number "
            name="port"
            value={inputs.port || ""}
            className="inputs"
            placeholder="eg:5000"
            onChange={handleInputChange}
          />
        </div>

        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="suffix">
            Suffix*
          </label>
          <input
            type="text "
            name="suffix"
            value={inputs.suffix || ""}
            className="inputs"
            placeholder="streaming/channels"
            onChange={handleInputChange}
          />
        </div>
        <button className="submit-btn" onClick={handleUrlGeneration}>
          Submit
        </button>
      </form>
    </div>
  );
};
export default AddTable;

