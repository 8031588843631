import React from 'react'
import HomeNavBar from '../../Components/Layout/HomeNavBar'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import BottomNavBar from '../../Components/Layout/BottomNavBar'

const Layout = () => {
  return (
    <div className='layout'>
      <HomeNavBar/>
      <Outlet />
      <Footer/>
      <div className ='BottomNav'>
        <BottomNavBar/>
      </div>
    </div>


  )
}

export default Layout