import React, { createContext, useState, useEffect, useContext } from 'react';
import io from 'socket.io-client';
import Swal from 'sweetalert2';
import useFeaturesAlert from '../Components/CustomHooks/useFeaturesAleart'
import useLastAlertData from '../Components/CustomHooks/useLastAlertData'; // Adjust the path

const WebSocketContext = createContext();

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ serverUrl, dbName, clientName, children }) => {
  const [messages, setMessages] = useState([]);
  const { getLastAlertData, aData } = useLastAlertData(dbName);
  const token = localStorage.getItem('token');
  const showFeaturesAlert = useFeaturesAlert();

  useEffect(() => {
    if (!token) {
      console.log("No valid token found. WebSocket connection will not be established.");
      return;
    }

    const socket = io(serverUrl, {
      query: {token,clientName },

    });
    console.log("fgfgfggfdg",socket)

    socket.on("connect", () => {
      console.log("Connected to Socket server");
      socket.emit('chat message', `${clientName}`);
    });

    socket.on('message', (Message) => {
      console.log(Message);
    });

    socket.on('customFeatureEvent', (feature) => {
      console.log(feature);
      showFeaturesAlert({
        title: 'Feature Warning',
        text: feature[0].msg,
        icon: 'warning',
      });
    });

    socket.on('alertData', () => {
      getLastAlertData();
    });

    socket.on('notification', (newMessage) => {
      console.log("Received notification:", newMessage);
      setMessages((prevMessages) => [...prevMessages, newMessage]);

      if (token) {
        Swal.fire({
          title: `${newMessage.device_id} ${newMessage.status} 'process'`,
          text: newMessage.processId,
          icon: newMessage.status === "completed" ? "success" : "info",
          position: 'top-end',
          customClass: {
            popup: 'small-swal-popup',
            title: 'small-swal-title',
            content: 'small-swal-content',
            backdrop: 'swal2-backdrop-hide',
          },
          showConfirmButton: false,
          container: 'no-overlay',
          backdrop: 'none',
          timer: 3000,
          focusConfirm: false,
          willOpen: (popup) => {
            popup.focus();
            setTimeout(() => {
              if (document.activeElement === popup) {
                popup.blur();
              }
            }, 0);
          },
        });
      }
    });

    const intervalId = setInterval(() => {
      socket.emit("ping", { clientName });
      console.log("Ping sent to server");
    }, 7000);

    return () => {
      socket.disconnect();
      clearInterval(intervalId);
    };
  }, [serverUrl, clientName, token, getLastAlertData]);

  useEffect(() => {
    if (aData) {
      console.log('Alert Data received:', aData);
    }
  }, [aData]);

  return (
    <WebSocketContext.Provider value={{ messages }}>
      {children}
    </WebSocketContext.Provider>
  );
};
export default WebSocketProvider;