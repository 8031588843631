import React, { useState } from "react";
import { AiOutlineLaptop, AiOutlineSetting, AiOutlineEye, AiOutlineEdit, AiOutlineBell } from "react-icons/ai"; // Import icons from react-icons
import "../../Assets/Styles/tabbar.css"; // Add your CSS file path here
import { useNavigate } from "react-router-dom";

const TabBar = () => {
  const [activeTab, setActiveTab] = useState("deviceManagement");
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case "deviceManagement":
        navigate("/dashboard");
        break;
      case "featureMapping":
        navigate("features");
        break;
      case "liveView":
        navigate("live");
        break;
      case "annotation":
        navigate("Annotationandlabelling");
        break;
      case "alertManagement":
        navigate("alertsetting");
        break;
      default:
        break;
    }
  };

  return (
      <div className="tab-bar">
        <button
          className={`tab-button ${
            activeTab === "deviceManagement" ? "active" : ""
          }`}
          onClick={() => handleTabClick("deviceManagement")}
        >
          <AiOutlineLaptop className="tab-icon" />
          <span>Device</span>
        </button>
        <button
          className={`tab-button ${
            activeTab === "featureMapping" ? "active" : ""
          }`}
          onClick={() => handleTabClick("featureMapping")}
        >
          <AiOutlineSetting className="tab-icon" />
          <span>Feature</span>
        </button>
        <button
          className={`tab-button ${
            activeTab === "liveView" ? "active" : ""
          }`}
          onClick={() => handleTabClick("liveView")}
        >
          <AiOutlineEye className="tab-icon" />
          <span>Live</span>
        </button>
        <button
          className={`tab-button ${
            activeTab === "annotation" ? "active" : ""
          }`}
          onClick={() => handleTabClick("annotation")}
        >
          <AiOutlineEdit className="tab-icon" />
          <span>Annotation</span>
        </button>
        <button
          className={`tab-button ${
            activeTab === "alertManagement" ? "active" : ""
          }`}
          onClick={() => handleTabClick("alertManagement")}
        >
          <AiOutlineBell className="tab-icon" />
          <span>Alert</span>
        </button>
      </div>
  );
};

export default TabBar;
