// src/GuideCard.js

import React from 'react';
import '../../Assets/Styles/GuideCard.css'; // Import CSS for styling

const AnprGuideCard = () => {
  return (
    <div className="guide-card">
      <h2>Welcome to ANPR System</h2>
      <p>Our ANPR (Automatic Number Plate Recognition) system comes with two powerful features:</p>
      <h3>1. General Detection</h3>
      <p>
        This feature allows the system to detect number plates from any vehicle in the entire camera frame.
        It's ideal for monitoring broad areas where vehicle positions may change frequently.
      </p>
      <h3>2. Detection within a Bounding Box</h3>
      <p>
        This feature enables the system to focus on specific areas defined by bounding boxes. It improves accuracy
        and reduces processing load by limiting detection to critical zones such as entry gates or designated parking spots.
      </p>
      <p>
        Choose the detection mode that best fits your needs and start monitoring efficiently with our ANPR system.
      </p>
    </div>
  );
};

export default AnprGuideCard;
