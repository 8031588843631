// PublicRoutes.js
import Home from "../../pages/Home/Home";
import AboutUs from "../../pages/Home/AboutUs";
import Login from "../../pages/Login/Login";
import SignUpPage from "../../pages/Login/SignUpPage";
import OurService from "../../pages/Home/OurService";
import Product from "../../pages/Home/Product";
import WhyBuy from "../../pages/Home/WhyBuy";

const PublicRoutes = [
    { index: true, element: <Home /> },
    { path: "aboutus", element: <AboutUs /> },
    { path: "ourservice", element: <OurService /> },
    { path: "product", element: <Product /> },
    { path: "whybuyaisolution", element: <WhyBuy /> },
    { path: "login", element: <Login /> },
    { path: "signup", element: <SignUpPage /> },
  
 
];

export default PublicRoutes;
