import React from 'react';
import './SkeletonComponent.css'; // Assuming you've moved styles to a separate CSS file

const ConfigSkeleton = () => {
  return (
    <div className="configStream">
      <h1 className="skeleton-title">Loading...</h1>
      <div className={`canvas-container1`}>
        
      </div>
      <div style={{ paddingLeft: "1rem" }} className="button-container">
        <div className="configStream__btn skeleton-button">Loading...</div>
        <div className="configStream__btn skeleton-button">Loading...</div>
    
          <div className="configStream__btn skeleton-button">Loading...</div>
    
      </div>
    </div>
  );
};

export default ConfigSkeleton;
