import { useRoutes, useNavigate, Navigate } from "react-router-dom";
import "./App.css";
import UserProvider from "./ContextApi/MyContext";
import PageNotFound from "./pages/ErrorHandlingPage/PageNotFound";
import React, { useEffect, useState } from "react";
import axios from "axios";
import PublicLayout from "./pages/Home/Layout";
import "@fontsource/alatsi";
import "@fontsource/open-sans";
import "@fontsource/pridi";
import "@fontsource/poppins";
import PrivateRoute from "./Components/Routers/PrivateRoute";
import PublicRoutes from "./Components/Routers/PublicRoutes";
import PublicProvider from "./ContextApi/PublicProvider";
import PrivateRoutes from "./Components/Routers/PrivateRoutes";
import ModelNotificationCard from "./Components/Notification/ModelNotificationCard";
import { WebSocketProvider } from "./ContextApi/WebSocketContext";

function App() {
  const navigate = useNavigate();
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem("theme") || "light";
  });

  const clientData = JSON.parse(localStorage.getItem("client"));
  const clientName = clientData ? clientData.clientName : null;
  const username = clientData ? clientData.username : null;
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  // const messages = useWebSocket(`${baseUrl}`, clientName, username);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    console.log("Click");
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const token = localStorage.getItem("token");
  const clientRole = localStorage.getItem("role");
  const isLoggedIn = token !== undefined && token !== null && token !== "";

  const routing = useRoutes([
    { path: "/", element: <PublicLayout />, children: PublicRoutes },
    {
      path: "/dashboard",
      element: isLoggedIn ? <PrivateRoute /> : <Navigate to="/login" />,
      children: PrivateRoutes,
    },
    { path: "*", element: <PageNotFound /> },
  ]);
  // rtsp://dhruv:Dhruv@9987@192.168.1.6:554/Streaming/channels/101

  useEffect(() => {
    const fetchProtectedData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("No token available");

        const response = await axios.get(`${baseUrl}/api/protected`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          console.log("Data:", response.data);
        } else {
          console.warn("Unexpected response status:", response.status);
        }
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 401:
              console.log(err.response.data.message);
              console.error("Unauthorized:", err.response.data.message);
              alert("Your Session is expired");
              navigate("/");
              break;
            case 403:
              console.error("Forbidden:", err.response.data.message);
              break;
            case 404:
              console.error("Not Found:", err.response.data.message);
              break;
            case 500:
              console.error(
                "Internal Server Error:",
                err.response.data.message
              );
              break;
            default:
              console.error("Error:", err.response.data.message);
              break;
          }
        } else if (err.request) {
          console.error("No response received:", err.request);
        } else {
          console.error("Error in request setup:", err.message);
        }
      }
    };

    fetchProtectedData();
  }, []);

  return (
    <div className={`App ${theme}`}>
      {isLoggedIn ? (
        <UserProvider toggleTheme={toggleTheme} theme={theme}>
          <WebSocketProvider serverUrl={baseUrl} dbName={clientName} clientName={username}>
            <ModelNotificationCard title="New Notification" />
            {routing}
          </WebSocketProvider>
        </UserProvider>
      ) : (
        <PublicProvider>{routing}</PublicProvider>
      )}
    </div>
  );
}

export default App;
