import React, { useContext, useEffect } from "react";
import "../../Assets/Styles/device.css";
import LocalisationProvider from "../../Components/Table/LocalisationProvider";
import { UserContext } from "../../ContextApi/MyContext";
import { useOutletContext } from "react-router-dom";
import ClientManagement from "../ClientManagment";
const Device = () => {
  const { deviceData, GetDeviceData } = useContext(UserContext);
  const { clientRole } = useOutletContext();

  useEffect(() => {
    GetDeviceData();
  }, []);

  return (
    <div className="device">
      {clientRole === "user" ? (
        <LocalisationProvider deviceData={deviceData} />
      ) : (
        <ClientManagement />
      )}
    </div>
  );
};

export default Device;
