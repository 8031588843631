import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { motion, AnimatePresence } from 'framer-motion';
import { ReactTyped as Typed } from 'react-typed';

import video1 from '../../Assets/Videos/v2.mp4';
import video2 from '../../Assets/Videos/v1.mp4';

const mediaItems = [
  { type: 'video', url: video1, text: "Welcome to Our AI Project", textColor: "#FFF" },
  { type: 'video', url: video2, text: "Explore Cutting-Edge Features", textColor: "#FF5733" },
];

const CustomSlider = () => {
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false);
    }, 8000); // Show intro for 5 seconds (adjusted for typewriting)
    return () => clearTimeout(timer);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '85vh' }}>
      <AnimatePresence>
        {showIntro && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, transition: { duration: 1.8 } }}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                color: '#FFF',
                fontSize: '2.5rem',
                textAlign: 'center',
                backgroundImage: `url('../Assets/Images/backgroundcst.jpg')`, // Replace with your image path
                backgroundSize: 'cover', // Ensures the image covers the entire background
                backgroundPosition: 'center', // Centers the background image
                position: 'absolute',
                width: '100%',
                zIndex: 2, // Ensures the intro is above the video
              
            }}
          >
            <div>
              <Typed
                strings={["Welcome to the Future of AI", "Experience Cutting-Edge Innovation"]}
                typeSpeed={50}
                backSpeed={30}
                backDelay={1500}
                loop={false}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <Slider {...settings}>
        {mediaItems.map((item, index) => (
          <motion.div
            key={index}
            data-index={index}
            initial={{ opacity: showIntro ? 0 : 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            style={{ position: 'relative' }}
          >
            {item.type === 'video' ? (
              <video
                src={item.url}
                loop
                muted
                autoPlay
                playsInline
                style={{ width: '100%', height: '85vh', objectFit: 'cover' }}
              />
            ) : (
              <img
                src={item.url}
                alt={`slide-${index}`}
                style={{ width: '100%', height: '85vh', objectFit: 'cover' }}
              />
            )}
           
          </motion.div>
        ))}
      </Slider>
    </div>
  );
};

export default CustomSlider;
