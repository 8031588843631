import { Box, Typography } from "@mui/material";
import React from "react";

const JourneyWithUs = () => {
  return (
    <div className="content4Image" >
      <Typography variant="h3" sx={{textAlign:'center',paddingTop:{md:'10px',xl:'40px'},paddingBottom:'20px',fontFamily:'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',fontSize:{xs:"2rem",sm:'2.2rem'},fontWeight:"700"}} >Journey With Us:</Typography>
        <Typography variant="p" sx={{fontSize:{xs:'1rem',sm:'1.3rem',xl:'1.4rem'},textAlign:{xs:"initial",xl:'center'},fontFamily:"egoe UI, Tahoma, Geneva, Verdana, sans-serif",paddingBottom:{sm:'20px',xl:'100px'},width:{sm:'100%',xl:'80%'},margin:'0 auto',fontWeight:'500'}} >
        Your journey with BuyAIsolution is a unique collaboration where your
           challenges fuel our innovation. From the first conversation to the
           final implementation, we tailor every step to your vision. Together,
           we don't just adapt to change; we lead it. Join us in redefining
           what's possible, and let's build a future where your success story
           becomes our proudest achievement.
        </Typography>
    </div>
  );
};

export default JourneyWithUs;
