import React, { useContext, useEffect, useState } from "react";
import Card from "../../Components/Utils/Card";
import "../../Assets/Styles/live.css";
import { UserContext } from "../../ContextApi/MyContext";

const AnprCount = () => {
  // const [grid, setGrid] = useState(3);
  const { anprDevices, GetDeviceData  } = useContext(UserContext);
   useEffect(()=>{
    GetDeviceData()
  },[])
  return (
    <div className="anprpage">
      <div className="people-title">
        <h3>Anpr Camera List</h3>
      </div>
      <div className={`cards`}>
      {anprDevices.map((item, i) => (
            <Card key={item.device_id} id={i + 1} item={item} />
        ))}
      </div>
    </div>
  );
};

export default AnprCount;