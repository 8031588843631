import React from "react";
import logo from "../../../src/Assets/Images/AiLogo.png";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
const Footer = () => {
  return (
    <div className="footer__container">
      <footer className="footer">
        <div className="footer__logo--box">
          <img className="footer__logo" src={logo} alt="Full Logo" />
        </div>
        <div className="footer__row">
          <div className="col-1-of-2">
            <div className="footer__navigation">
              <ul className="footer__list">
                <li className="footer__items">
                  <Link to="aboutus" className="footer__link">
                    About Us
                  </Link>
                </li>
                <li className="footer__items">
                  <Link to="ourservice" className="footer__link">
                    Our Service
                  </Link>
                </li>
                <li className="footer__items">
                  <Link to="product" className="footer__link">
                    Products
                  </Link>
                </li>
                <li className="footer__items">
                  <Link to="whybuyaisolution" className="footer__link">
                    Why BUYAISolution
                  </Link>
                </li>
                <li className="footer__items"></li>
              </ul>
              <div className="icons">
                {/* <Link to="https://www.instagram.com/conceptservetechnologies"> <FaWhatsapp className='whatsappicon' size={40} color='white'/></Link> */}
                <Link to="https://www.instagram.com/conceptservetechnologies">
                  {" "}
                  <FaInstagram className="instaicon" size={40} color="white" />
                </Link>
                <Link to="https://www.facebook.com/conceptserve">
                  {" "}
                  <FaFacebookF className="fbicon" size={39} color="white" />
                </Link>
                <Link to="https://www.linkedin.com/company/conceptserve-technologies">
                  {" "}
                  <FaLinkedinIn
                    className="linkedinicon"
                    size={39}
                    color="white"
                  />
                </Link>
                <Link to="https://x.com/ConceptServe">
                  <FaXTwitter className="twittericon" style={{marginLeft:"10px"}} size={40} color="white" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-1-of-2">
            <div className="footer__copyright">
              <Link to="" className="footer__link">
                Inquiry: info@conceptserve.com,
              </Link>
              <br />
              <p className="footer__link">
                B-1104, Mondeal Heights, Sarkhej - Gandhinagar highway,
              </p>
              <p className="footer__link">
                {" "}
                next to Novotel Hotel, Ramdev Nagar, Ahmedabad - 380015.
                Gujarat, India.
              </p>
              <p className="footer__link">&copy; conceptserve.com!.</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
