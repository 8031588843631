import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import AddAlertModal from "../../Components/Utils/AddAlertModal";
import "../../Assets/Styles/smsAlert.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { UserContext } from "../../ContextApi/MyContext";

import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import api from "../../Components/Utils/api";

const SmsAlertManagment = () => {
  const [alertUsers, setAlertUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { SuccessAlert, ErrorAlert, DeleteAlert, clientData, theme } =
    useContext(UserContext);
  // const [clientName, setClientName]= useState()
  const subtitleRef = useRef(null);

  const clientName = clientData ? clientData.clientName : null;
  const modalClassName =
    theme === "dark"
      ? "smsAlert__container dark-theme"
      : "smsAlert__container light-theme";

  const getAlertUser = () => {
    api
      .get(`api/devices/${clientName}/alertuser`)
      .then((response) => {
        console.log("response.data", response.data);
        setAlertUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setAlertUsers([]);
      });
  };

  useEffect(() => {
    getAlertUser();
  }, []);

  const handleDeleteUser = (id) => {
    console.log(id);
    DeleteAlert().then((result) => {
      if (result.isConfirmed) {
       api.delete(
            `api/devices/${clientName}/alertuser/${id}`
          )
          .then((response) => {
            getAlertUser();
            console.log(response.data);
            SuccessAlert(response.data.message);
          })
          .catch((error) => {
            console.error("Error deleting user:", error);
            ErrorAlert("Error deleting user");
          });
      }
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  function afterOpenModal() {
    // Access the subtitleRef.current to manipulate the subtitle element
    if (subtitleRef.current) {
      subtitleRef.current.style.backgroundColor = "red"; // Example style change
    }
  }

  const handleToggle = (id, isActive) => {
    console.log(id, isActive);
    const newStatus = isActive === 1 ? true : false;
    console.log(newStatus);
    api
      .put(
        `api/devices/${clientName}/toggleAlertUser/${id}`,
        {
          status: !newStatus,
        }
      )
      .then((response) => {
        SuccessAlert(response.data.message);
        getAlertUser();
      })
      .catch((error) => {
        console.error("Error toggling item:", error);
        ErrorAlert(error);
      });
  };
  return (
    // <ThemeProvider > {/* Wrap your component with ThemeProvider */}
    <div className={modalClassName}>
      <h2>Alert Users</h2>
      <button
        className="add_alertbtn"
        style={{ margin: "10px", float: "right" }}
        onClick={() => setIsModalOpen(true)}
      >
        Add User
      </button>
      <Modal
        isOpen={isModalOpen}
        contentLabel="Example Modal"
        className="AlertModal"
        overlayClassName="Overlay"
        onRequestClose={closeModal}
        onAfterOpen={afterOpenModal}
        ariaHideApp={false}
      >
        <AddAlertModal
          subtitleRef={subtitleRef}
          closeModal={closeModal}
          fetchAlertUser={getAlertUser}
          SuccessAlert={SuccessAlert}
          ErrorAlert={ErrorAlert}
          clientData={clientData}
        />
      </Modal>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className="sms__tabler-row">
              <TableCell className="sms__tblecell">Sr.no</TableCell>
              <TableCell className="sms__tblecell">User Name</TableCell>
              <TableCell className="sms__tblecell">User Email</TableCell>
              <TableCell className="sms__tblecell">User Contact</TableCell>
              <TableCell className="sms__tblecell">Delete User</TableCell>
              <TableCell className="sms__tblecell">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alertUsers.map((user, index) => (
              <TableRow key={user.id}>
                <TableCell className="data-cell">{index}</TableCell>
                <TableCell className="data-cell">{user.userName}</TableCell>
                <TableCell className="data-cell">{user.userEmail}</TableCell>
                <TableCell className="data-cell">{user.userContact}</TableCell>
                <TableCell>
                  <button
                    variant="contained"
                    className="add_alert-delete"
                    onClick={() => handleDeleteUser(user.id)}
                  >
                    <RiDeleteBin6Line></RiDeleteBin6Line>
                  </button>
                </TableCell>
                {/* <TableCell className='data-cell'>{user.status.data}</TableCell> */}
                <TableCell className="data-cell">
                  {user.status.data[0] === 1 ? (
                    <>
                      <FaToggleOn
                        onClick={() =>
                          handleToggle(user.id, user.status.data[0])
                        }
                        color="green"
                      />
                    </>
                  ) : (
                    <>
                      <FaToggleOff
                        color="red"
                        onClick={() =>
                          handleToggle(user.id, user.status.data[0])
                        }
                      />
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    // </ThemeProvider>
  );
};

export default SmsAlertManagment;
