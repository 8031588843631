import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import MultiSelectDropdown from "./MultiSelectDropdown";
import "../../Assets/Styles/GroupNotificationConfigComponent.css";
import DateTimePicker from "../../Components/Utils/DateTimePicker";
import { UserContext } from "../../ContextApi/MyContext";
import GroupNotificationSkeleton from "../LoaderSkeleton/GroupNotificationSkeleton";
import api from "../Utils/api";

const GroupNotificationConfigComponent = ({
  groupData,
  handleUserCheckboxChange,
  activeUserGroupData,
  sentWebhookData,
  setFeature,
  endDate,
  startDate,
  userList,
  handleNumberOfPeopleForAlert,
  handleAlertConditionChange,
  handleStartTimeChange,
  handleEndTimeChange,
  anprBoxCoordinates,
  setAnprBoxCoordinates,
  isLoading,
  selectedModels,
  setModelList,
  modelList,
  setSelectedModels,
  setIsOpen,
  isOpen,
  selectedValue,
  options,
  setSelectedValue,
  counts,
  setCounts,
  clientData,
  condition,
  numberOfPeople,
  setNumberOfPeople,
  configDataPost,
  deviceId,
  isTimeSpecific,
  setIsTimeSpecific
}) => {
  const [indexes, setIndexes] = useState(
    userList.map((user, index) => ({
      index,
      sms_status: 0,
      whatsapp_status: 0,
      email_status: 0,
    }))
  );
  const clientName = clientData ? clientData.clientName : null;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userGroups, setUserGroups] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [trainedModalList, setTrainedModalList] = useState();
  const [selectedModel, setSelectedModel] = useState("");
  const regex = /^\/dashboard\/features\/smartbox\/\w+/;
  const regex2 = /^\/dashboard\/features\/(anpr|smartbox)\/\w+/;
  const regexANPR = /^\/dashboard\/features\/(anpr)\/\w+/;
  const regexobject = /^\/dashboard\/features\/(objectcount)\/\w+/;
  const pythonApiUrl = process.env.REACT_APP_MODEL_TRAIN_API_BASE_URL;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const storageData = JSON.parse(localStorage.getItem("activeDeviceData"));
  const { SuccessAlert, ErrorAlert, isSkeletonLoading } = useContext(UserContext);

  const streamData = storageData
    ? storageData.find((device) => device.device_id === deviceId)
    : null;

  const [pathName, setPathName] = useState("");

  const [activeSection, setActiveSection] = useState("");
  const [valueOfTrainedModel, setValueOfTrainedModel] = useState();


  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleTrainedModalChange = (e) => {
    setValueOfTrainedModel(e.target.value);
  };
  useEffect(() => {
    setPathName(location.pathname);
    if (regexANPR.test(pathName) || regexobject.test(pathName)) {
      setIsChecked(true);
    }
  }, []);

  const handleAnprBoxCoordinates = (event) => {
    const { name, checked } = event.target;
    setAnprBoxCoordinates((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  useEffect(() => {
    const processUsers = () => {
      const newIndexArray = userList.map((user) => {
        const activeUser = activeUserGroupData?.find(
          (activeUser) => user.userName === activeUser.userName
        );
        if (activeUser) {
          const smsStatus = activeUser.sms_status.data[0];
          const whatsappStatus = activeUser.whatsapp_status.data[0];
          const emailStatus = activeUser.email_status.data[0];
          return {
            index: userList.indexOf(user),
            sms_status: smsStatus,
            whatsapp_status: whatsappStatus,
            email_status: emailStatus,
          };
        } else {
          return {
            index: userList.indexOf(user),
            sms_status: 0,
            whatsapp_status: 0,
            email_status: 0,
          };
        }
      });

      setIndexes(newIndexArray);
    };

    const mapUsersToGroups = () => {
      const userGroupMap = {};

      groupData.forEach((group) => {
        group.members.forEach((member) => {
          userGroupMap[member] = group.groupName;
        });
      });

      setUserGroups(userGroupMap);
    };

    processUsers();
    mapUsersToGroups();
  }, [userList, activeUserGroupData, groupData, pathName]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    console.log("in handle select");

    setSelectedValue(option);
    console.log("SelectedValue", selectedValue);
    setIsOpen(false);
  };

  useEffect(() => {
    GetModelList();
  }, []);

  const GetModelList = () => {
    api.get(`/papi/webhook/get_trained_models`)
      .then((response) => {
        if (response.data) {
          setTrainedModalList(response.data.custom_trained_models || []);
          setModelList(response.data.trained_models || []);
        } else {
          console.error("Unexpected response structure:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the model list!", error);
      });
  };

  const handleSubmit = () => {
    const pathArray = pathName.split("/");
    setFeature(pathArray[3]);
    sentWebhookData(pathArray[3], isChecked, activeSection);
    // GetSingleObjectCount()
    setIsSubmitting(true);

    setTimeout(() => {
      console.log("Form submitted!");
      setIsSubmitting(false);
    }, 2000);
  };

  function handleButtonClick(section) {
    if (activeSection === section) {
      console.log("section", section);
      setActiveSection("");
    } else {
      setActiveSection(section);
      console.log("section", section);
      if (activeSection == "multipleObjects") {
        setNumberOfPeople(undefined);
      } else if (activeSection == "people") {
        setSelectedModels(new Array(0));
      }
    }
  }

  async function handleTrainSubmit() {
    const pythonApiUrl = process.env.REACT_APP_MODEL_TRAIN_API_BASE_URL;
    const processId = Math.floor(100000 + Math.random() * 900000).toString();
    const payload = {
      rtsp_url: streamData?.url,
      deviceId: deviceId,
      modelname: valueOfTrainedModel,
      operation: "start",
      processId: processId,
      db_name: clientName,
      count: `${condition}${numberOfPeople}`,
    };
    const featureName = "customTrainModel";
    try {
      const response = await api.post(
        `/webhook/detect`,
        payload
      );

      if (response.status >= 200 && response.status < 300) {
        configDataPost(processId, featureName);
        console.log("Request succeeded");
      } else {
        console.error("Request failed", response.statusText);
      }
    } catch (error) {
      ErrorAlert("Error making the request:", error.message);
    }
  }

  return (
    <>
      {isSkeletonLoading ? 
        <GroupNotificationSkeleton />
       : 
        <div className="smsModal" style={{ transition: "opacity 0.5s ease" }}>
          <h2 className="alertTitle">Device Configaration for Alert</h2>
          <h2 className="alertmsg">
            Want to Receive an alert when object detected
          </h2>
          <div className="GroupAlertSelection">
            <p className="selectedUser">Selected Notification Group</p>
            {isLoading && isLoading ? (
              <div>Loading.....</div>
            ) : (
              <div className="groupContainer">
                <div className="groupColumn">
                  <p className="userContact">SMS Group</p>
                  {userList.map((user, index) => (
                    <div className="servicesInputBox" key={index}>
                      <input
                        type="checkbox"
                        value={user.userName}
                        checked={indexes.some(
                          (obj) =>
                            obj["index"] === index && obj["sms_status"] === 1
                        )}
                        onChange={(event) => {
                          handleUserCheckboxChange(event, index, "sms_status");
                          console.log(index);
                        }}
                      />
                      <label>{user.userName}</label>
                    </div>
                  ))}
                </div>

                <div className="groupColumn">
                  <p className="userContact">WhatsApp Group</p>
                  {userList.map((user, index) => (
                    <div className="servicesInputBox" key={index}>
                      <input
                        type="checkbox"
                        value={user.userName}
                        checked={indexes.some(
                          (obj) =>
                            obj["index"] === index &&
                            obj["whatsapp_status"] === 1
                        )}
                        onChange={(event) => {
                          handleUserCheckboxChange(
                            event,
                            index,
                            "whatsapp_status"
                          );
                          console.log(index);
                        }}
                      />
                      <label>{user.userName}</label>
                    </div>
                  ))}
                </div>

                <div className="groupColumn">
                  <p className="userContact">Email Group</p>
                  {userList.map((user, index) => (
                    <div className="servicesInputBox" key={index}>
                      <input
                        type="checkbox"
                        value={user.userName}
                        checked={indexes.some(
                          (obj) =>
                            obj["index"] === index && obj["email_status"] === 1
                        )}
                        onChange={(event) => {
                          handleUserCheckboxChange(
                            event,
                            index,
                            "email_status"
                          );
                          console.log(index);
                        }}
                      />
                      <label>{user.userName}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="userAlertSelection">
              <div className="checkbox-con">
                {!regexANPR.test(pathName) && !regexobject.test(pathName) && (
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                )}
                {!regexANPR.test(pathName) && !regexobject.test(pathName) && (
                  <span className="dateTime__checkbox">
                    Click here to set a time-specific alert.
                  </span>
                )}

                {isChecked ||
                  (regexANPR.test(pathName) && (
                    <div>
                      <DateTimePicker
                        startDate={startDate}
                        endDate={endDate}
                        handleStartTimeChange={handleStartTimeChange}
                        handleEndTimeChange={handleEndTimeChange}
                      />
                    </div>
                  ))}
                {!isChecked ||
                  (regex2.test(pathName) && (
                    <div>
                      <DateTimePicker
                        startDate={startDate}
                        endDate={endDate}
                        handleStartTimeChange={handleStartTimeChange}
                        handleEndTimeChange={handleEndTimeChange}
                      />
                    </div>
                  ))}
              </div>

              {regex.test(pathName) ? (
                <div className="dropdown">
                  <button onClick={handleToggle} className="dropdown-toggle">
                    {selectedValue || "Select an option"}
                  </button>
                  {isOpen && (
                    <ul className="dropdown-menu">
                      {options.map((option, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelect(option) > { option }}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ) : null}
              {regexANPR.test(pathName) ? (
                <>
                  <div className="object">
                    <label>
                      <input
                        type="checkbox"
                        name="sendBoxCoordinates" // Name matches the key in the state object
                        checked={anprBoxCoordinates.sendBoxCoordinates}
                        onChange={handleAnprBoxCoordinates}
                      />
                      Click to Send box coordinates
                    </label>
                  </div>
                  <div className="object">
                    <label>
                      <input
                        type="checkbox"
                        name="checkVehicleType" // Name matches the key in the state object
                        checked={anprBoxCoordinates.checkVehicleType}
                        onChange={handleAnprBoxCoordinates}
                      />
                      Click to check vehicle type or categories with ANPR
                    </label>
                  </div>
                </>
              ) : null}

              {regex2.test(pathName) ? null : (
                <>
                  <div className="objeccount_butns">
                    <button
                      className={
                        activeSection === "people" && "active_button-col"
                      }
                      onClick={() => handleButtonClick("people")}
                    >
                      People count
                    </button>
                    <button
                      className={
                        activeSection === "ownModel" && "active_button-col"
                      }
                      onClick={() => handleButtonClick("ownModel")}
                    >
                      Own model count
                    </button>
                    <button
                      className={
                        activeSection === "multipleObjects" &&
                        "active_button-col"
                      }
                      onClick={() => handleButtonClick("multipleObjects")}
                    >
                      Multiple Object count
                    </button>
                  </div>
                  {activeSection === "ownModel" && (
                    <div>
                      <div>
                        <p className="condinal__title">
                          Set trained own modal count condition:
                        </p>
                        <div className="conditionalbox">
                          <div className="conditionalinput">
                            <input
                              type="number"
                              name="numberOfPeopleToDetect"
                              className="numberOfPeopleToDetect"
                              placeholder="Enter "
                              onChange={handleNumberOfPeopleForAlert}
                            />

                            <select
                              onChange={handleAlertConditionChange}
                              className="numberOfPeopleToDetect"
                            >
                              <option value="equals">Equals </option>
                              <option value="atleast">Atleast</option>
                              <option value="greater_than">Greater than</option>
                              <option value="atmost">Atmost</option>
                              <option value="smaller_than">Smaller than</option>
                            </select>
                            <select
                              onChange={handleTrainedModalChange}
                              className="numberOfPeopleToDetect"
                            >
                              <option value={null}>Select Model </option>
                              {trainedModalList?.map((model, index) => (
                                <option key={index} value={model}>
                                  {model}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeSection === "people" && (
                    <div>
                      <DateTimePicker
                        startDate={startDate}
                        endDate={endDate}
                        handleStartTimeChange={handleStartTimeChange}
                        handleEndTimeChange={handleEndTimeChange}
                      />
                      <div>
                        <p className="condinal__title">
                          Set alert peaple count condition:
                        </p>
                        <div className="conditionalbox">
                          <div className="conditionalinput">
                            <input
                              type="number"
                              name="numberOfPeopleToDetect"
                              className="numberOfPeopleToDetect"
                              placeholder="Enter "
                              onChange={handleNumberOfPeopleForAlert}
                            />

                            <select
                              onChange={handleAlertConditionChange}
                              className="numberOfPeopleToDetect"
                            >
                              <option value="equals">Equals </option>
                              <option value="atleast">Atleast</option>
                              <option value="greater_than">Greater than</option>
                              <option value="atmost">Atmost</option>
                              <option value="smaller_than">Smaller than</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <>
                    {activeSection === "multipleObjects" && (
                      <>
                        <div>
                          <label>
                            <input
                              type="checkbox"
                              checked={isTimeSpecific}
                              onChange={(e) => setIsTimeSpecific(e.target.checked)}
                            />
                            Click For Time-Specific Alert
                          </label>
                        </div>
                        {isTimeSpecific && (
                          <div>
                            <DateTimePicker
                              startDate={startDate}
                              endDate={endDate}
                              handleStartTimeChange={handleStartTimeChange}
                              handleEndTimeChange={handleEndTimeChange}
                            />
                          </div>
                        )}

                        <MultiSelectDropdown
                          counts={counts}
                          setCounts={setCounts}
                          options={modelList}
                          selectedOptions={selectedModels}
                          setSelectedOptions={setSelectedModels}
                        />
                      </>
                    )}
                  </>
                </>
              )}
            </div>
          </div>
          <div className="SubmitSchduler">
            {activeSection === "ownModel" ? (
              <button
                className="SubmitSchdulerbtn"
                onClick={handleTrainSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting.." : "Submit"}
              </button>
            ) : (
              <button
                className="SubmitSchdulerbtn"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting.." : "Submit"}
              </button>
            )}
          </div>
        </div>
      }
    </>
  );
};

export default GroupNotificationConfigComponent;
