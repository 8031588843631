import React, { useContext, useState } from "react";
import ImageCanvas from "./ImageCanvas";
import AnnotationList from "./AnnotationList";
import ImageUploader from "./ImageUploader";
import useImageAnnotations from "../../Components/CustomHooks/useImageAnnotations";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../ContextApi/MyContext";
import CryptoJS from 'crypto-js';
import ModelNotificationCard from "../../Components/Notification/ModelNotificationCard";

function ImageManager() {
  const { clientData , ErrorAlert, SuccessAlert} = useContext(UserContext);
  const clientName = clientData ? clientData.clientName : null;
  const [newAnnotation, setNewAnnotation] = useState([]);
  const location = useLocation();
  const projectNameFiles = location.state;
  const projectNameFile = projectNameFiles.projectName + projectNameFiles.projectId;
  const {
    currentIndex,
    setCurrentIndex,
    annotationsByImage,
    handleAnnotationsChange,
    images,
    setImages,
    getAnnotationsData,
  } = useImageAnnotations();
  const [label, setLabel] = useState("");
  const [modelName, setModelName] = useState();
  const [isLabelSet, setIsLabelSet] = useState(false); // New state to manage if label is set
  const [loading, setLoading] = useState(false);
  const handleThumbnailClick = (index) => setCurrentIndex(index);
  const handleClearImages = () => {
    setImages([]);
    setCurrentIndex(0);
    setNewAnnotation([]);
    setIsLabelSet(false); // Reset label state
  };
  // handleClearImages()

  const handleLabelChange = (e) => {
    const newLabel = e.target.value;
    setLabel(newLabel);
    setIsLabelSet(!!newLabel); // Set the flag based on whether a label is provided
  };
  const handleModelChange = (e) => {
    const newmodel = e.target.value;
    setModelName(newmodel);
  };

  const data = getAnnotationsData();


  const handleSendData = async () => {
    const secretKey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_HEADER_SECRET_KEY.padEnd(32, ' ')); // Pad key to 32 bytes
    const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
  
    // Encrypt parameters
    const encryptedProjectName = CryptoJS.AES.encrypt(projectNameFile, secretKey, { iv }).toString();
    const encryptedOperation = CryptoJS.AES.encrypt("start", secretKey, { iv }).toString();
    const encryptedPythonApi = CryptoJS.AES.encrypt(process.env.REACT_APP_MODEL_TRAIN_API_BASE_URL, secretKey, { iv }).toString();
  
    // Convert IV to base64 string for sending it along with the request
    const ivBase64 = CryptoJS.enc.Base64.stringify(iv);
  
    // Construct the URL with encrypted parameters and IV
    const url = new URL(`${process.env.REACT_APP_API_BASE_URL}/api/annotation/${clientName}/createFolderAndFile`);
    url.searchParams.append("projectName", encryptedProjectName);
    url.searchParams.append("pythonApi", encryptedPythonApi);
    url.searchParams.append("modelName", modelName);
    url.searchParams.append("iv", ivBase64); 
    url.searchParams.append("operation", encryptedOperation); 
    
    if (!modelName || !data || data.length === 0) {
      ErrorAlert('Please enter a model name and ensure data is not empty');
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(url.toString(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const result = await response.json();
      console.log("Data successfully sent:", result.message);
      SuccessAlert(result.message);
    } catch (error) {
      console.error("Error sending data:", error);
      ErrorAlert(error.message);
    }finally {
      setLoading(false);  // Set loading to false after the request is complete
    }
  };
  return (
    <div className="imgslider">
      <div className="imgslider__container">
        <div className="imgslider__left">
          {images.length > 0 ? (
            <ImageCanvas
              imageSrc={images[currentIndex]?.src}
              annotations={annotationsByImage[currentIndex] || []}
              setAnnotations={handleAnnotationsChange}
              currentIndex={currentIndex}
              handleClearImages={handleClearImages}
              setLabel={setLabel}
              label={label}
              images={images}
              setCurrentIndex={setCurrentIndex}
              newAnnotation={newAnnotation}
              setNewAnnotation={setNewAnnotation}
              isLabelSet={isLabelSet} // Pass the label state to ImageCanvas
              setIsLabelSet={setIsLabelSet} // Pass the setter to ImageCanvas
              handleLabelChange={handleLabelChange}
            />
          ) : (
            <ImageUploader setImages={setImages} />
          )}
          <AnnotationList
            annotations={annotationsByImage[currentIndex] || []}
            label={label}
            handleSendData={handleSendData}
            handleLabelChange={handleLabelChange}
            modelName={modelName}
            handleModelChange={handleModelChange}
            loading={loading}

          />
        </div>
        <div className="imgslider__right">
          <h3>Image Thumbnails</h3>
          <div className="imgthumbnails">
            {images.map((image, index) => (
              <div
                className="imgthumbnails__box"
                key={index}
                onClick={() => handleThumbnailClick(index)}
                style={{ cursor: "pointer", marginBottom: "10px" }}
              >
                <img src={image.src} alt={`Thumbnail ${index}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageManager;
