import React from "react";
import "../../Assets/Styles/WhyBuy.css";
import img from "../../Assets/Images/business-success-report-graph-concept.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Banner from "../../Components/WhyBuyAi/Banner";
import ContentSection from "../../Components/WhyBuyAi/Contentsection";
import GrowWithUs from "../../Components/WhyBuyAi/GrowWithUs ";
import Banner2 from "../../Components/WhyBuyAi/Banner2";
import JourneyWithUs from "../../Components//WhyBuyAi/JourneyWithUs";

const WhyBuy = () => {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/signup`;
    navigate(path);
  };
  return (
    <div className="whyby">
  <Banner />
  <div className="whyby__content">
    <div className="contentl__container ">
    <div className="content1">
      <p>
        Explore a new world of opportunities with BuyAISolution. Our revolutionary technology is designed to transform your vision into reality. Join us in embracing the future and staying on the leading edge.
      </p>
    </div>

    </div>
    <div className="contentl__container2 ">
    <div className="content2">

      <div className="paragraph">
        <p>
          At BuyAISolution, we use cutting-edge AI to transform business operations and open doors for you to succeed. Workers can concentrate on important projects and use data-driven insights to encourage innovation and growth by automating time-consuming processes. This increases productivity and helps you make wise decisions, which helps you stay ahead in a competitive industry. Our timely service and customized messaging increase customer satisfaction and foster loyalty and confidence.
        </p>
        <p>
          We safeguard your data and procedures with strong security mechanisms, guaranteeing industry compliance and peace of mind. Consider our creative solutions to stay ahead of the competition, keep up with technology developments, and maintain a competitive advantage in the ever-changing business environment.
        </p>
      </div>

      <ul className="custom-list">
        <li>At BuyAISolution, we:
          <ul className="nested-list">
            <li>Use cutting-edge AI to transform business operations and open doors for your success.</li>
            <li>Automate time-consuming processes, enabling workers to focus on important projects.</li>
            <li>Utilize data-driven insights to encourage innovation and growth.</li>
            <li>Increase productivity and help you make wise decisions to stay ahead in a competitive industry.</li>
            <li>Provide timely service and customized messaging to:
              <ul className="nested-list">
                <li>Increase customer satisfaction.</li>
                <li>Foster loyalty and confidence.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>We safeguard your data and procedures by:
          <ul className="nested-list">
            <li>Implementing strong security mechanisms.</li>
            <li>Ensuring industry compliance for peace of mind.</li>
          </ul>
        </li>
        <li>Consider our creative solutions to:
          <ul className="nested-list">
            <li>Stay ahead of the competition.</li>
            <li>Keep up with technology developments.</li>
            <li>Maintain a competitive advantage in the ever-changing business environment.</li>
          </ul>
        </li>
      </ul>
    </div>
        </div>
  </div>
  <GrowWithUs />
  <JourneyWithUs />
</div>

  );
};

export default WhyBuy;
