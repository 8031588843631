import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthForm from "../../Components/Auth/AuthForm";
import Swal from "sweetalert2";

const SignPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // New state for toggling password visibility
  const [errors, setErrors] = useState({});
  const SuccessAlert = (success) => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: success,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const ErrorAlert = (error) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error,
    });
  };
  const validateForm = () => {
    const errors = {};
    if (!username.trim()) {
      errors.username = "Username is required";
    }
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }
    if (!password.trim()) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }
    if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
    return errors;
  };


const handleSignup = async () => {
  try {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/signup`, {
        username,
        email,
        password,
      });

      switch (response.status) {
        case 201:
          SuccessAlert("User created successfully");
          // signUpModelTrainApi()
          navigate("/");
          break;
        case 200:
          SuccessAlert("User updated successfully");
          navigate("/");
          break;
        case 400:
          ErrorAlert("Bad request - please check your input");
          break;
        case 500:
          ErrorAlert("Internal server error - please try again later");
          break;
        default:
          ErrorAlert("An unknown error occurred");
          break;
      }
    }
  } catch (error) {
    if (error.response?.data?.error === "Duplicate entry 'ajit' for key 'users.username'") {
      ErrorAlert("User is already registered");
    } else {
      ErrorAlert("An unexpected error occurred");
    }
  }
};

  

  return (
    <AuthForm
      title="Sign Up"
      fields={[
        {
          id: "username",
          type: "text",
          placeholder: "Username",
          required: true,
          value: username,
          onChange: (e) => setUsername(e.target.value),
          label: "Username",
          error: errors.username,
        },
        {
          id: "email",
          type: "email",
          placeholder: "Email Address",
          required: true,
          value: email,
          onChange: (e) => setEmail(e.target.value),
          label: "Email Address",
          error: errors.email,
        },
        {
          id: "password",
          type: showPassword ? "text" : "password", // Show password characters if showPassword is true
          placeholder: "Password",
          required: true,
          value: password,
          onChange: (e) => setPassword(e.target.value),
          label: "Password",
          error: errors.password,
        },
        {
          id: "confirmPassword",
          type: showPassword ? "text" : "password", // Show password characters if showPassword is true
          placeholder: "Confirm Password",
          required: true,
          value: confirmPassword,
          onChange: (e) => setConfirmPassword(e.target.value),
          label: "Confirm Password",
          error: errors.confirmPassword,
        },
      ]}
      showPassword={showPassword} // Pass the showPassword state to the AuthForm component
      onTogglePassword={() => setShowPassword(!showPassword)} // Pass a function to toggle the showPassword state
      onSubmit={handleSignup}
      buttonText="Sign Up"
      LoginSingupText={{
        text: "Already have an account",
        message: "Log in",
      }}
      routeName="/login"
    />
  );
};

export default SignPage;
