import React, { useContext, useState } from "react";
import { GrClose } from "react-icons/gr";
import "../../Assets/Styles/addForm.css";
import { UserContext } from "../../ContextApi/MyContext";
import useFeatureExists from "../CustomHooks/useCheckFeture";
import api from "../Utils/api";

const EditTable = ({ closeModal, rowData }) => {
  const { SuccessAlert, ErrorAlert, GetDeviceData, clientData, theme ,clientfeatures} =
    useContext(UserContext);
    const { checkFeatureExists } = useFeatureExists(clientfeatures);
    const hasAnprFeature = checkFeatureExists('anpr');
    const hasObjectFeature = checkFeatureExists('objectcount');
    const hasSmartFeature  = checkFeatureExists('smartbox');
  // const [clientName, setClientName] = useState("")
  const modalClassName =
    theme === "dark" ? "add-table dark-theme" : "add-table light-theme";
  const [inputs, setInputs] = useState({
    device_id: rowData?.device_id,
    device_name: rowData?.device_name,
    user_name: rowData?.user_name,
    device_type: rowData?.device_type,
    password: rowData?.password,
    ip: rowData?.ip,
    port: rowData?.port,
    suffix: rowData?.suffix,
    url: rowData?.url,
    gate_type: rowData?.gate_type,
  });

  const clientName = clientData ? clientData.clientName : null;
  console.log("Client Name:", clientName);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const editRowData = () => {
    api
      .patch(
        `/api/devices/update/${clientName}/${rowData?.id}`,
        inputs
      )
      .then((response) => {
        console.log(response.data.message);
        SuccessAlert(response.data.message);
        closeModal();
        GetDeviceData();
      })
      .catch((error) => {
        console.error(error);
        ErrorAlert(error.message);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    editRowData();
  };

  return (
    <div className={modalClassName}>
      <div className="modal-title">
        <h4>Edit Device info</h4>
        <button className="modal-closebtn" onClick={closeModal}>
          <GrClose />
        </button>
      </div>
   
      <form onSubmit={handleSubmit} className="addform">
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="device_name">
            Device Name*
          </label>
          <input
            type="text"
            name="device_name"
            value={inputs.device_name || ""}
            className="inputs"
            placeholder="Device Name"
            onChange={handleInputChange}
          />
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="user_name">
            User Name
          </label>
          <input
            type="text"
            name="user_name"
            value={inputs.user_name || ""}
            className="inputs"
            placeholder="User Name"
            onChange={handleInputChange}
          />
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="password">
            Password*
          </label>
          <input
            type="text"
            name="password"
            value={
              inputs.password && inputs.password.length > 3 
                ? inputs.password.slice(0, 3) + "*".repeat(inputs.password.length - 3) 
                : inputs.password || ""
            }
            className="inputs"
            placeholder="Password"
            onChange={handleInputChange}
          />
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="device_type">
            Device Type*
          </label>
          <select
            onChange={handleInputChange}
            className="inputs"
            value={inputs.device_type}
            name="device_type"
          >
            <option className="inputs" value={null}>
              Select Services*
            </option>
            {hasAnprFeature && <option className="inputs" value="objectcount">
              Object Count
            </option>}
            {hasObjectFeature && <option className="inputs" value="anpr">
              Anpr(Automatic number-plate recognition)
            </option>}
            {hasSmartFeature && <option className="inputs" value="smartbox">
              SmartBox Detection
            </option>}
          </select>
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="device_type">
            |Select Gate Type*
          </label>
          <select
            onChange={handleInputChange}
            className="inputs"
            value={inputs.gate_type}
            name="gate_type"
          >
            <option className="inputs" value={null}>
              Select Gate*
            </option>
            {hasSmartFeature && <option className="inputs" value="in">
              In
            </option>}
            {hasSmartFeature && <option className="inputs" value="out">
              Out
            </option>}
          </select>
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="device_id">
            Device Id*
          </label>
          <input
            type="number "
            name="device_id"
            value={inputs.device_id || ""}
            className="inputs"
            placeholder="Device Id"
            onChange={handleInputChange}
            readOnly
          />
        </div>

        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="ip">
            Device Ip*
          </label>
          <input
            type="number "
            name="ip"
            value={inputs.ip || ""}
            className="inputs"
            placeholder="Device Ip"
            onChange={handleInputChange}
          />
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="port">
            Port*
          </label>
          <input
            type="number "
            name="port"
            value={inputs.port || ""}
            className="inputs"
            placeholder="Port"
            onChange={handleInputChange}
          />
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="suffix">
            suffix*
          </label>
          <input
            type="number "
            name="suffix"
            value={inputs.suffix || ""}
            className="inputs"
            placeholder="Suffix"
            onChange={handleInputChange}
          />
        </div>
        <div className="Add_form__group">
          <label className="add__form-label" htmlFor="url">
            url*
          </label>
          <input
            type="number "
            name="url"
            value={inputs.url || ""}
            className="inputs"
            placeholder="Url"
            onChange={handleInputChange}
          />
        </div>
        <button className="submit-btn" type="submit">
          Save
        </button>
      </form>
    </div>
  );
};
export default EditTable;
