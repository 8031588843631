import React from "react";
import DeviceReport from "./DeviceReports";
import DeviceActiveChart from "./DeviceActiveChart";
import ActiveUserChart from "./ActiveUserChart";
import "../../Assets/Styles/reports.css";

function Reports() {
  return (
    <>
      <div className="report">
        <div className="row1">
          <DeviceReport />
          <DeviceActiveChart />
        </div>
        <div className="row1">
        <ActiveUserChart />
        <div className="device-report">
       </div>

        </div>
      </div>
    </>
  );
}

export default Reports;
