import React, { useState } from "react";

const MultiSelectDropdown = ({
  options,
  selectedOptions,
  setSelectedOptions,
  counts,
  setCounts
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedOptions([...selectedOptions, value]);
      setCounts({ ...counts, [value]: 1 });
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
      const newCounts = { ...counts };
      delete newCounts[value];
      setCounts(newCounts);
    }
  };

  const handleCountChange = (event, option) => {
    
    const newCount = event.target.value;
    setCounts({ ...counts, [option]: newCount });
    console.log("selectedOptions",counts)
  };

  return (
    <div className="multi-select">
      <p>Object Count one or multiple:</p>
      <div className="multi-select-dropdown">
        <div className="dropdown-header" onClick={handleDropdownToggle}>
          {selectedOptions.length > 0
            ? selectedOptions.join(", ")
            : "Select Objects"}
          <span className="dropdown-arrow">{isOpen ? "▲" : "▼"}</span>
        </div>
        {isOpen && (
          <div className="dropdown-menu">
            {options?.map((option, index) => (
              <div key={index} className="dropdown-option">
                <label>
                  <input
                    type="checkbox"
                    value={option}
                    checked={selectedOptions.includes(option)}
                    onChange={handleOptionChange}
                  />
                  {option}
                </label>
                {selectedOptions.includes(option) && (
                  <input
                    type="number"
                    min="1"
                    value={counts[option]}
                    onChange={(event) => handleCountChange(event, option)}
                    className="count-input"
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
