import React from 'react';
// import '../../Assets/Styles/Banner.css';
import { Box } from '@mui/material';
import Robot2 from '../../Assets/Images/Robot2.png'

const Banner = () => {
  return (
    <div className='banner'>
      <h1 className='banner__h1 buysol'>BuyAISolution</h1>
      <h1 className='banner__h1 welcomes'>Welcomes</h1>
      <h1 className='banner__h1 you'>You</h1>
      {/* <Box  className='myrobot' >
        <img src={Robot2} alt='robotimage' style={{width:"100%",height:'100%'}} />
      </Box> */}
    </div>
  );
};

export default Banner;
