import React, { useState, useEffect, useContext } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import "../../Assets/Styles/reports.css";
import { UserContext } from "../../ContextApi/MyContext";
import api from "../../Components/Utils/api";

const ActiveUserChart = () => {
  const { clientData } = useContext(UserContext);
  const clientName = clientData ? clientData.clientName : null;
  const [deviceData, setDeviceData] = useState([]);
  const [activeUsers, setActiveUsers] = useState(null);
  const [data, setData] = useState();
  const [SimulatedData, setSimulatedData] = useState([]);
  const baseUrl = process.env.REACT_APP_API_BASE_URL

  function callFunc(data) {
    console.log("data", data);
    const groupedStats = data?.reduce((acc, curr) => {
      const { device_id, sms_status, whatsapp_status, email_status } = curr;
      const { data: smsData } = sms_status;
      const { data: whatsappData } = whatsapp_status;
      const { data: emailData } = email_status;

      if (!acc[device_id]) {
        acc[device_id] = {
          device_id,
          smsCount: smsData[0],
          whatsappCount: whatsappData[0],
          emailCount: emailData[0],
        };
      } else {
        acc[device_id].smsCount += smsData[0];
        acc[device_id].whatsappCount += whatsappData[0];
        acc[device_id].emailCount += emailData[0];
      }

      return acc;
    }, {});
    // Convert groupedStats object into an array
    const statsArray = Object.values(groupedStats);
    setSimulatedData(statsArray);
  }

  const getDevicesActiveAlertData = async () => {
    try {
      const response = await api.get(
        `/api/devices/${clientName}/GetDevicesActiveAlertData`
      );

      //   setData(response.data.data)
      callFunc(response.data.data);
    } catch (error) {
      console.log("Error in getDevicesActiveAlertData", error);
    }
  };

  useEffect(() => {
    getDevicesActiveAlertData();
  }, []);

  const handleBarMouseEnter = (data, index) => {
    // Display active user IDs when a bar is hovered
    setActiveUsers({
      sms: SimulatedData.sms_active_user_ids,
      whatsapp: SimulatedData.whatsapp_active_user_ids,
      email: SimulatedData.email_active_user_ids,
    });
  };

  const handleBarMouseLeave = () => {
    // Hide active user IDs when mouse leaves the bar
    setActiveUsers(null);
  };

  return (
    <div className="device-report">
      <h1>Device Report</h1>
      <BarChart
        width={800}
        height={400}
        data={SimulatedData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="device_id" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="smsCount"
          fill="#8884d8"
          onMouseEnter={handleBarMouseEnter}
          onMouseLeave={handleBarMouseLeave}
        />
        <Bar
          dataKey="whatsappCount"
          fill="#82ca9d"
          onMouseEnter={handleBarMouseEnter}
          onMouseLeave={handleBarMouseLeave}
        />
        <Bar
          dataKey="emailCount"
          fill="#ffc658"
          onMouseEnter={handleBarMouseEnter}
          onMouseLeave={handleBarMouseLeave}
        />
      </BarChart>
    </div>
  );
};

export default ActiveUserChart;
