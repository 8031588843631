import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-modal";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { UserContext } from "../ContextApi/MyContext";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import EditClientForm from "../Components/Admin/EditClientForm";
import { FaToggleOff, FaToggleOn } from "react-icons/fa6";
import "../Assets/Styles/clientManagement.css";
import "../Assets/Styles/table.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoMdClose } from "react-icons/io";
import api from "../Components/Utils/api";
import { createTheme, ThemeProvider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const UserManagement = () => {
  const { userData, theme, SuccessAlert, ErrorAlert } = useContext(UserContext);

  const modalClassName = theme === "dark" ? "dark" : "light";
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [rowData, setRowData] = useState({});
  const [clients, setClients] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowForFeature, setSelectedRowForFeature] = useState(null);
  const [features, setFeatures] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const handleDateChange = (date, index, activeStatus, featureId) => {
    const newDates = [...selectedDates];
    newDates[index] = date;
    setSelectedDates(newDates);
  };
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
   
  });
  const lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
   
  });
  const currentTheme = theme === "dark" ? darkTheme : lightTheme;

  useEffect(() => {}, [selectedDates]);
  const fetchClients = async () => {
    try {
      const response = await api.get(`/api/clientlist`);
      setClients(response.data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);
  const handleFeatureClick = (feature) => {
    // Perform action based on the feature clicked
    handleMenuClose();
  };
  const columns = useMemo(
    () => [
      { accessorKey: "user_id", header: "ID" },
      { accessorKey: "username", header: "Username" },
      { accessorKey: "email", header: "Email" },

      {
        accessorKey: "features",
        header: "Feature Actions",
        Cell: ({ row }) => (
          <div>
            <IconButton onClick={(event) => handleMenuClick(event, row)}>
              <MoreVertIcon />
            </IconButton>
          </div>
        ),
      },
      {
        accessorKey: "status",
        header: "User Status",
        Cell: ({ row }) => (
          <button onClick={() => handleUserToggleClick(row)}>
            {row.original.status?.data[0] == 1 ? (
              <FaToggleOn color="green" />
            ) : (
              <FaToggleOff color="red" />
            )}
          </button>
        ),
      },
    ],
    []
  );

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowForFeature(row);
    setIsOpen(true);
    fetchFeatureList();
  };
  const fetchFeatureList = async () => {
    try {
      const response = await api.get(
        `/api/featurelist?client_id=${selectedRowForFeature.original?.user_id}`
      );

      const featureList = response.data;

      const newDates = featureList.map((client) => {
        if (client?.expiration_date) {
          return new Date(client.expiration_date);
        }
        return new Date();
      });

      setFeatures(featureList);
      setSelectedDates(newDates);
    } catch (error) {
      console.error("Error fetching feature list:", error);
    }
  };

  useEffect(() => {}, [selectedDates]);

  useEffect(() => {
    fetchFeatureList();
  }, [selectedRowForFeature]);

  const handleUserToggleClick = async (row) => {
    const response = await api
      .patch(`/api/toggleclient/${row?.original?.user_id}`)
      .then((response) => {
        SuccessAlert(response.data);
        fetchClients();
      })
      .catch((error) => {
        console.error("Error updating user status:", error);
        ErrorAlert(error);
      });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = () => {
    if (selectedRow) {
      editUserModal(selectedRow.original.user_id);
    }
    handleMenuClose();
  };

  const handleFeatureToggle = (activeStatus, index) => {
    const newStatus = activeStatus == 1 ? 0 : 1;
    const newFeatures = [...features];
    newFeatures[index].active = newStatus;
    setFeatures(newFeatures);
  };
  const handleFeatureSave = (features, index) => {
    const newExpiredDate = selectedDates[index];
    const dateObject = new Date(newExpiredDate);
    const formattedDate = dateObject?.toISOString().split(".")[0];
    console.log(formattedDate);

    const { user_id } = selectedRowForFeature?.original;

    api
      .put(`/api/updatefeatures`, {
        userId: user_id,
        featureId: features[index].feature_id,
        isActive: features[index].active == 1 ? true : false,
        expirationDate: formattedDate,
      })
      .then((response) => {
        SuccessAlert("User status updated successfully!");
        fetchFeatureList();
      })
      .catch((error) => {
        console.error("Error updating user status:", error);
        ErrorAlert(error);
      });
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setIsOpen2(false);
  }

  const editUserModal = (id) => {
    const user = userData.find((user) => user.user_id === id);
    if (user) {
      setRowData(user);
      setIsOpen2(true);
    } else {
      console.log("User not found for ID:", id);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: clients,
  });
  return (
    <div className="react__table-container">
      <Modal
        isOpen={modalIsOpen2}
        contentLabel="Edit User"
        className="ClientModal"
        onRequestClose={closeModal}
        ariaHideApp={false}
        overlayClassName="ClientOverlay"
        style={{
          content: {
            backdropFilter: "saturate(180%) blur(10px)",
            boxShadow: "rgba(0,0,0, 0.2)",
          },
        }}
      >
        <EditClientForm closeModal={closeModal} rowData={rowData} />
      </Modal>

      <div className={`react_table ${modalClassName}`}>
        <ThemeProvider theme={currentTheme}>
            <MaterialReactTable table={table} />
        </ThemeProvider>
      </div>
      <Modal
        isOpen={modalIsOpen}
        contentLabel="Edit User"
        className="ClientModal"
        overlayClassName="ClientOverlay"
        onRequestClose={closeModal}
        ariaHideApp={false}
        style={{
          content: {
            backdropFilter: "saturate(180%) blur(10px)",
            boxShadow: "rgba(0,0,0, 0.2)",
          },
        }}
      >
        <div className={`featureContainer ${modalClassName}`}>
          <button className="mdclose" onClick={closeModal}>
            <IoMdClose className="mdClose" />
          </button>
          <div className={`featureHeader ${modalClassName}`}>
            <h5>Feature Name:</h5>
            <h5>Expiry Date:</h5>
          </div>
          {features?.map((client, index) => (
            <div key={index} className="client-item">
              <button
                className="togglefeature"
                onClick={() => handleFeatureToggle(client.active, index)}
              >
                {client.active == 1 ? (
                  <FaToggleOn color="green" />
                ) : (
                  <FaToggleOff color="red" />
                )}
              </button>
              <div className="featureName">{client?.feature_name}</div>
              <DatePicker
                selected={selectedDates[index] || new Date()}
                key={index}
                onChange={(date) => {
                  handleDateChange(
                    date,
                    index,
                    client.active,
                    client?.feature_id
                  );
                }}
                dateFormat="yyyy/MM/dd"
                // style={{ width: '10px' }}
                className="customDatePickerWidth"
              />
              <button
                className="featureSaveButton"
                onClick={() => handleFeatureSave(features, index)}
              >
                Save
              </button>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default UserManagement;
