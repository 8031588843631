import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../ContextApi/MyContext';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';

const ProcessHistory = () => {
    const {processHistory, GetProcessHistory, theme}=useContext(UserContext);
    const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const modalClassName =
    theme === "dark"
      ? "smsAlert__container dark-theme"
      : "smsAlert__container light-theme";
  
  useEffect(() => {
    GetProcessHistory();
    console.log('processHistory>', processHistory);
}, []);
  
  const isEmpty = (obj) => {
    return !obj || Object.keys(obj).length === 0;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isEmpty(processHistory)) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      NO DATA
    </div>
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, processHistory.length - page * rowsPerPage);
    return (
        <div className={modalClassName}>
      <h2 style={{margin:"1rem 0"}}>Alert List</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className="sms__tabler-row">
              <TableCell className="sms__tblecell">Sr.no</TableCell>
              <TableCell className="sms__tblecell">Process Id</TableCell>
              <TableCell className="sms__tblecell">Device Id</TableCell>
              <TableCell className="sms__tblecell">Feature Name</TableCell>
              <TableCell className="sms__tblecell">Status</TableCell>
              <TableCell className="sms__tblecell">Start Time</TableCell>
              <TableCell className="sms__tblecell">End Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processHistory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((list, index) => (
              <TableRow key={list.id}>
                <TableCell className="data-cell">{page * rowsPerPage + index + 1}</TableCell>
                <TableCell className="data-cell">{list.processId}</TableCell>
                <TableCell className="data-cell">{list.device_id}</TableCell>
                <TableCell className="data-cell">{list.featureName}</TableCell>
                <TableCell className="data-cell">{list.status}</TableCell>
                <TableCell className="data-cell">{list.startTime}</TableCell>
                <TableCell className="data-cell">{list.endTime}</TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={processHistory.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
    )
}

export default ProcessHistory