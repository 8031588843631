// src/GuideCard.js

import React from 'react';
import '../../Assets/Styles/GuideCard.css'; // Import CSS for styling

const SmartGuide = () => {
  return (
    <div className="guide-card">
      <h2>Welcome to SmartBox Detection System</h2>
      <p>Our SmartBox Detection system offers advanced features tailored for comprehensive monitoring:</p>
      
      <h3>1. Person Detection</h3>
      <p>
        This feature allows detection of persons within the camera feed. It includes:
      </p>
      <ul>
        <li>Detection without Bounding Box: Identifies persons across the entire camera frame, useful for broad area monitoring.</li>
        <li>Detection with Bounding Box: Focuses on specific areas defined by bounding boxes, enhancing accuracy in targeted zones like entryways or critical points.</li>
      </ul>
      
      <h3>2. Object Detection</h3>
      <p>
        Our system detects various objects within the camera view. Key features include:
      </p>
      <ul>
        <li>Detection with Bounding Box: Identifies objects and highlights them within bounding boxes, allowing for precise tracking and monitoring.</li>
        <li>Object Movement Tracking: Tracks movement of detected objects within their respective bounding boxes, providing real-time updates on object activity.</li>
      </ul>
      
      <p>
        Choose the detection mode that best fits your monitoring needs and leverage our SmartBox Detection system for enhanced surveillance and security.
      </p>
    </div>
  );
};

export default SmartGuide
    
