import { MRT_ActionMenuItem, MaterialReactTable, useMaterialReactTable } from "material-react-table";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FaToggleOff, FaToggleOn } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import Modal from "react-modal";
import "../../Assets/Styles/table.css";
import '../../Assets/Styles/theme-dark.css';
import '../../Assets/Styles/theme-light.css';
import { UserContext } from "../../ContextApi/MyContext";
import AddTable from "../Table/AddTable";
import EditTable from "../Table/EditTable";
import api from "./api";

const ReactTable = () => {
  const {
    deviceData,
    GetDeviceData,
    setDeviceData,
    SuccessAlert,
    ErrorAlert,
    DeleteAlert,
    clientData,
  } = useContext(UserContext);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [rowData, setRowData] = useState({});
  const subtitleRef = useRef(null);

  const data = deviceData;
  const [columnVisibility, setColumnVisibility] = useState({
    device_id: false,
    device_name: true,
    password: false,
    url: true,
    port: false,
    suffix: false,
  });

  const clientName = clientData ? clientData.clientName : null;

  useEffect(() => {
    setColumnVisibility({
      device_id: false,
      device_name: true,
      password: false,
      url: true,
      port: false,
      suffix: false,
    });

    GetDeviceData();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("deviceData")) {
      GetDeviceData();
    } else {
      setDeviceData(JSON.parse(localStorage.getItem("deviceData")));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("deviceData", JSON.stringify(deviceData));
  }, [deviceData]);

  const deviceList = ["Anpr", "CCTV", "ZKTecho", "Normal"];

  const editModal = (id) => {
    const demo = JSON.parse(localStorage.getItem("deviceData"));
    const rowData = demo?.find((device) => device.id === id);
    if (rowData) {
      setRowData(rowData);
      setIsOpen2(true);
    } else {
      console.log("Row data not found for device_id:", id);
    }
  };

  const handleDelete = (id) => {
    DeleteAlert().then((result) => {
      if (result.isConfirmed) {
        api
          .delete(`/api/devices/${clientName}/${id}`)
          .then((response) => {
            SuccessAlert("Deleted successfully!");
            GetDeviceData();
          })
          .catch((error) => {
            console.error("Error deleting:", error);
            ErrorAlert("Error deleting device");
          });
      }
    });
  };

  const handleToggle = (id, isActive) => {
    const newStatus = isActive === 1 ? false : true;
    api
      .put(`/api/devices/${clientName}/${id}`, {
        status: newStatus,
      })
      .then((response) => {
        SuccessAlert(response.data.message);
        GetDeviceData();
      })
      .catch((error) => {
        console.error("Error toggling item:", error);
        ErrorAlert(error);
      });
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function afterOpenModal() {
    if (subtitleRef.current) {
      subtitleRef.current.style.backgroundColor = 'red';
    }
  }

  const columns = useMemo(
    () => [
      { accessorKey: "id", header: "Id" },
      { accessorKey: "device_id", header: "Device Id" },
      { accessorKey: "device_name", header: "Device Name", filterVariant: "text", size: 200 },
      { accessorKey: "user_name", header: "User Name", filterVariant: "text", size: 200 },
      { accessorKey: "password", header: "Password" },
      { accessorKey: "device_type", header: "Device Service", filterVariant: "multi-select", filterSelectOptions: deviceList },
      { accessorKey: "url", header: "Device URL", enableClickToCopy: true },
      { accessorKey: "ip", header: "Device Ip" },
      { accessorKey: "port", header: "Device Port" },
      { accessorKey: "suffix", header: "Suffix" },
     
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    state: { columnVisibility },
    onColumnVisibilityChange: setColumnVisibility,
    enableColumnPinning: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActionMenuItems: ({ row, table }) => [
      <MRT_ActionMenuItem
        icon={<CiEdit color="#7b57e0"/>}
        key="edit"
        label="Edit"
        onClick={() => editModal(row.original.id)}
        table={table}
      />,
      <MRT_ActionMenuItem
        icon={row.original.status?.data[0] === 1 ? <FaToggleOff /> : <FaToggleOn color="green"/>}
        key="toggle"
        label={row.original.status?.data[0] === 1 ? "Deactivate" : "Activate"}
        onClick={() => handleToggle(row.original.id, row.original.status.data[0])}
        table={table}
      />,
      <MRT_ActionMenuItem
      icon={<MdDelete  color="red"/>}
      key="delete"
      label="Delete"
      onClick={() => handleDelete(row.original.id)}
      table={table}
    />,
    ]
  });

  return (
    <div className="react__table-container">
      <div className="table-btn">
        <h1>Device List</h1>
        <button onClick={openModal} className="table-add-btn">Add Device</button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        contentLabel="Add Device"
        className="Modal"
        onAfterOpen={afterOpenModal}
        overlayClassName="Overlay"
        onRequestClose={closeModal}
        ariaHideApp={false}
        style={{ content: { backdropFilter: 'saturate(180%) blur(50px)' } }}
      >
        <AddTable subtitleRef={subtitleRef} closeModal={closeModal} />
      </Modal>
      <Modal
        isOpen={modalIsOpen2}
        contentLabel="Edit Device"
        className="Modal"
        onAfterOpen={afterOpenModal}
        overlayClassName="Overlay"
        onRequestClose={() => setIsOpen2(false)}
        ariaHideApp={false}
        style={{ content: { backdropFilter: 'saturate(180%) blur(10px)', boxShadow: "rgba(0,0,0, 0.2)" } }}
      >
        <EditTable subtitleRef={subtitleRef} closeModal={() => setIsOpen2(false)} rowData={rowData} />
      </Modal>
      <div className="react_table">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

export default ReactTable;
