import React, { createContext, useEffect, useMemo, useState } from "react";
import { useBlocker, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../Components/Utils/api";
import useFeatureExists from "../Components/CustomHooks/useCheckFeture";
export const UserContext = createContext();

const UserProvider = ({ children, toggleTheme, theme }) => {
  const [deviceData, setDeviceData] = useState([]);
  const [alertList, setAlertList] = useState([]);
  const [processHistory, setProcessHistory] = useState([]);
  const [matchedPeople, setMatchedPeople] = useState([]);
  const [uniquePeople, setUniquePeople] = useState([]);
  const [fetaureData, setFeatureData] = useState([]);
  const [activeDeviceData, setActiveDeviceData] = useState([]);
  const [error, setError] = useState(null);
  const [anprDevices, setAnprDevices] = useState([]);
  const [otdDevices, setOtdDevices] = useState([]);
  const [peopleDevices, setPeopleDevices] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState("Checking...");
  const navigate = useNavigate();
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const [clientfeatures, setClientFeatures] = useState([]);
  const { checkFeatureExists } = useFeatureExists(clientfeatures);
 
  const clientData = JSON.parse(localStorage.getItem("client"));
  const clientName = clientData ? clientData.clientName : null;
  const userName = clientData ? clientData.username : null;
  const userId = localStorage.getItem("client_id"); 
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
     fetchPorocessData();
   }, []);
 
   const fetchPorocessData = async () => {
     try {
       const responce = await api.get(
         `/api/devices/${clientName}/getconfigdata`
       );
       const processDta = responce.data.configdata[0];
       console.log(processDta.length)
       setNotificationCount(processDta.length);
     } catch (error) {
       console.log(error);
     }
   };
 
  useEffect(() => {
    // Simulate fetching data
    setTimeout(() => {
      setIsSkeletonLoading(false); // Data has been loaded
    }, 2000);
  }, []);
  useEffect(() => {
    const clientId = localStorage.getItem("client_id");
    const fetchClientsList = async () => {
      try {
        const response = await api.get(
          `/api/features?client_id=${clientId}`
        );
        const features = response.data;
        let results = [];
        await Promise.all(
          features.map(async (feature) => {
            let featureId = feature.feature_id;
            let featureName = feature.feature_name;
            let active = feature.active.data[0];
            results.push({ featureId, featureName, active });
          })
        );
        const activeFeatures = results.filter(
          (feature) => feature.active !== 0
      );
        setClientFeatures(activeFeatures);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchClientsList();
    // fetchClients();
  }, []);
  const featureAlert = async () => {
    try {
      const response = await api.post(
        `/api/checkexpiredate/${userId}`,
        {
          clientName:clientName,
          userName:userName,
        }
          
      );
      const data = response.data[0].msg;
      console.log(data)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchFeatureList = async () => {
    const clientId = localStorage.getItem("client_id");
    try {
      const response = await api.get(
        `/api/features?client_id=${clientId}`
      );
      const features = response?.data;
      let results = [];

      // Iterate over each feature asynchronously
      await Promise.all(
        features.map(async (feature) => {
          let featureId = feature.feature_id;
          let featureName = feature.feature_name;
          let active = feature.active.data[0];

          // Call setClientFeatures asynchronously and await its completion

          // Push result or any other data you want into the results array
          results.push({ featureId, featureName, active });
        })
      );
      const activeFeatures = results.filter(
        (feature) => feature.active !== 0
      );
      setClientFeatures(activeFeatures);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };


  useEffect(() => {
    featureAlert();
    GetDeviceData()
    fetchFeatureList();
  }, []);

  const SuccessAlert = (success) => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: success,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const ErrorAlert = (error) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error,
    });
  };

  const DeleteAlert = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        resolve(result);
      });
    });
  };

  const GetDeviceData = () => {
    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
  
    api
      .get(`/api/devices/${clientName}`)
      .then((response) => {
        if (response.data.devices && response.data.devices.length > 0) {
          const devices = response.data.devices[0];
          console.log(devices);
          setFeatureData(devices);
        }
        if (response.status === 400) {
          console.log("Bad Request 🥱");
        }
        if (response.status === 500) {
          console.log("Internal Server Error");
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  const GetAlertList = () => {
    api
      .get(`/api/devices/${clientName}/alertdatalist`)
      .then((response) => {
        if (response.status === 200) {
          const alertDataList = response.data;
          setAlertList(alertDataList);
        }
        if (response.status === 400) {
          console.log("Bad Request 🥱");
        }
        if (response.status === 500) {
          console.log("Internal Server Error");
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  const GetProcessHistory = async () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    try {
        const response = await api.get(`/api/devices/${clientName}/processhistory`);
        if (response.status === 200) {
            console.log('Process history data:', response.data.data);
            setProcessHistory(response.data.data);  // Ensure this is the correct structure
        } else if (response.status === 400) {
            console.log("Bad Request 🥱");
        } else if (response.status === 500) {
            console.log("Internal Server Error");
        }
    } catch (error) {
        console.error("Error fetching process history:", error);
        setError(error);
    }
};

  const GetMatchedPeople = async () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await api.get(`/api/devices/${clientName}/matcheddata`);
      if (response.status === 200) {
          console.log('Matched People data:', response.data);
          setMatchedPeople(response.data);  // Ensure this is the correct structure
      } else if (response.status === 400) {
          console.log("Bad Request 🥱");
      } else if (response.status === 500) {
          console.log("Internal Server Error");
      }
  } catch (error) {
      console.error("Error fetching matched people:", error);
      setError(error);
  }
  };
  const GetPeopleData = async (unique_id) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await api.get(`/api/devices/${clientName}/getbyuniqueid/${unique_id}`);
      if (response.status === 200) {
          console.log('Unique People data:', response.data);
          setUniquePeople(response.data);  // Ensure this is the correct structure
      } else if (response.status === 400) {
          console.log("Bad Request 🥱");
      } else if (response.status === 500) {
          console.log("Internal Server Error");
      }
  } catch (error) {
      console.error("Error fetching Unique people:", error);
      setError(error);
  }
  };


  const checkConnectionStatus = async () => {
    try {
      const response = await api.get(`/api/connection-status`);
      const data = response.data;
      console.log(data)
      setConnectionStatus(
        data.status === "connected" ? "Connected" : "Disconnected"
      );
      if (data.status !== "connected") {
        navigate("connection");
      }
    } catch (error) {
      setConnectionStatus("Disconnected");
      console.error("Error checking connection status:", error);
    }
  };
  

  useEffect(() => {
    const intervalId = setInterval(checkConnectionStatus, 5000); // Check every 5 seconds
    checkConnectionStatus(); // Initial chec
    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);
  const featureDatas = () => {
    const featureMap = new Map(
      clientfeatures.map((feature, index) => [feature.featureName, index])
    );
    const matchedDevices = fetaureData.filter((device) => {
      const featureIndex = featureMap.get(device.device_type.toLowerCase());
      return featureIndex !== undefined;
    });

    const activeDeviceData = matchedDevices.filter(
      (device) => device.status?.data?.[0] !== 0
    );
    const anprDevices = matchedDevices.filter(
      (device) =>
        device.device_type === "anpr" && device.status?.data?.[0] !== 0
    );
    const peopleDevices = matchedDevices.filter(
      (device) =>
        device.device_type === "objectcount" &&
        device.status?.data?.[0] !== 0
    );
    const otdDevices = matchedDevices.filter(
      (device) =>
        device.device_type === "smartbox" &&
        device.status?.data?.[0] !== 0
    );

    
    setAnprDevices(anprDevices);
    setOtdDevices(otdDevices);
    setPeopleDevices(peopleDevices);
    setDeviceData(matchedDevices);
    setActiveDeviceData(activeDeviceData);
    localStorage.setItem("deviceData", JSON.stringify(matchedDevices));
    localStorage.setItem(
      "activeDeviceData",
      JSON.stringify(activeDeviceData)
    );
  }; 

  useEffect(() => {
    featureDatas();
  }, [fetaureData]);

  const memoizedData = useMemo(() => ({
    deviceData,
    alertList,
    processHistory,
    matchedPeople,
    uniquePeople,
    activeDeviceData,
    error,
    SuccessAlert,
    ErrorAlert,
    DeleteAlert,
    GetDeviceData,
    setDeviceData,
    GetAlertList,
    setAlertList,
    setProcessHistory,
    GetProcessHistory,
    setMatchedPeople,
    setUniquePeople,
    GetMatchedPeople,
    GetPeopleData,
    anprDevices,
    otdDevices,
    peopleDevices,
    clientData,
    toggleTheme,
    theme,
    clientfeatures,
    isSkeletonLoading,
    notificationCount,
    fetchPorocessData

  }), [
    deviceData,
    alertList,
    processHistory,
    matchedPeople,
    uniquePeople,
    activeDeviceData,
    error,
    SuccessAlert,
    ErrorAlert,
    DeleteAlert,
    GetDeviceData,
    setDeviceData,
    GetAlertList,
    setAlertList,
    setProcessHistory,
    GetProcessHistory,
    setMatchedPeople,
    GetMatchedPeople,
    setUniquePeople,
    GetPeopleData,
    anprDevices,
    otdDevices,
    peopleDevices,
    clientData,
    toggleTheme,
    theme,
    clientfeatures,
    isSkeletonLoading,
    notificationCount,
    fetchPorocessData
  ]);


  return (
    <UserContext.Provider value={memoizedData}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
