import React, { useContext, useRef, useState } from "react";

import { GiHamburgerMenu } from "react-icons/gi";
import Modal from "react-modal";
import { NavLink } from "react-router-dom";
import "../../Assets/Styles/profile.css";
import { UserContext } from "../../ContextApi/MyContext";

const MobileSidebar = () => {
  const subtitleRef = useRef(null);
  const [modalIsOpen, setIsOpen] = useState();
  const { theme } = useContext(UserContext);
  const clientRole = localStorage.getItem('role');
  const modalClassName =
    theme === "dark" ? "mobileHeader dark" : "mobileHeader light";

  const handleToggal = () => {
    setIsOpen(!modalIsOpen);
  };

  function afterOpenModal() {
    // Access the subtitleRef.current to manipulate the subtitle element
    if (subtitleRef.current) {
      subtitleRef.current.style.backgroundColor = "red"; // Example style change
    }
  }

  return (
    <div className="mobile-con">
      <button onClick={handleToggal}>
        <GiHamburgerMenu className="hamBurger" />
      </button>
      <Modal
        isOpen={modalIsOpen}
        className={"mobile__model"}
        onAfterOpen={afterOpenModal}
        overlayClassName="modal__bg"
        onRequestClose={handleToggal}
        ariaHideApp={false}
        style={{
          content: {
            backdropFilter: "saturate(180%) blur(50px)",
          },
        }}
      >
        <div className={modalClassName}>
          {clientRole === 'user' ?
          <div className="mobileHeader-list">
            <NavLink to="/dashboard" className="mobile-header-link">
              <span>Device Management</span>
            </NavLink>
            <NavLink to="features" className="mobile-header-link">
              <span>Feature Mapping</span>
            </NavLink>
            <NavLink to="live" className="mobile-header-link">
              <span>Live View</span>
            </NavLink>
            <NavLink to="Annotationandlabelling" className="mobile-header-link">
              <span>Anotation</span>
            </NavLink>
            <NavLink to="alertsetting" className="mobile-header-link">
              <span>ALert Management</span>
            </NavLink>
          </div>
          :
          <div className="mobileHeader-list">
            <NavLink to="/dashboard" className="mobile-header-link">
              <span>User Management</span>
            </NavLink>
          </div>
          }
        </div>
      </Modal>
    </div>
  );
};

export default MobileSidebar;
