import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../Assets/Styles/Card.css";
import { GiCctvCamera } from "react-icons/gi";
import { HiOutlineChevronDoubleRight } from "react-icons/hi2";
import { encryptUrl } from './CryptoUtils';

const Card = ({ id, item }) => {
  const [coords, setCoords] = useState({ x: 50, y: 50 });

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setCoords({ x, y });
  };

  const queryString = new URLSearchParams({
    device_id: item.device_id,
    device_name:item.device_name,
  }).toString();
  const encryptedQueryString = encryptUrl(queryString);
  return (
    <Link
      className="cameraContainer"
      to={`${encodeURIComponent(encryptedQueryString)}?data=${encodeURIComponent(encryptedQueryString)}`}
      onMouseMove={handleMouseMove}
      style={{
        '--x': `${coords.x}px`,
        '--y': `${coords.y}px`,
      }}
    >
      <div className="camera_card">
        <div className="cameraTitle">
          <span className="title">{item.device_name}</span>
          <span className="title">Channel : {item.device_id}</span>
        </div>
        <div className="cameraVideo">
          <GiCctvCamera className="cctv" />
        </div>
        <div className="camera_heading">
          <span className="title">Service : {item.device_type}</span>
          <button className="cameraConfig">
            Camera Config <HiOutlineChevronDoubleRight />
          </button>
        </div>
      </div>
    </Link>
  );
};

export default Card;
