import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../ContextApi/MyContext';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, TablePagination, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

import { MdOutlineVisibility } from "react-icons/md";
import { MdOutlineVisibilityOff } from "react-icons/md";
const AllAlerts = () => {
  const { alertList, GetAlertList, theme } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const modalClassName =
    theme === "dark"
      ? "smsAlert__container dark-theme"
      : "smsAlert__container light-theme";
  
  useEffect(() => {
    GetAlertList();
    console.log('alertListttt', alertList);
  }, []);
  
  const isEmpty = (obj) => {
    return !obj || Object.keys(obj).length === 0;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewImage = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  if (isEmpty(alertList)) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      NO PROCESS ALERTS AVAILABLE
    </div>
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, alertList.length - page * rowsPerPage);

  return (
    <div className={modalClassName}>
      <h2 style={{margin:"1rem 0"}}>Alert List</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className="sms__tabler-row">
              <TableCell className="sms__tblecell">Sr.no</TableCell>
              <TableCell className="sms__tblecell">Feature Name</TableCell>
              <TableCell className="sms__tblecell">Device Id</TableCell>
              <TableCell className="sms__tblecell">Process Id</TableCell>
              <TableCell className="sms__tblecell">Detection Time</TableCell>
              <TableCell className="sms__tblecell">Start Time</TableCell>
              <TableCell className="sms__tblecell">End Time</TableCell>
              <TableCell className="sms__tblecell">View Image</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alertList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((list, index) => (
              <TableRow key={list.id}>
                <TableCell className="data-cell">{page * rowsPerPage + index + 1}</TableCell>
                <TableCell className="data-cell">{list.featureName}</TableCell>
                <TableCell className="data-cell">{list.device_id}</TableCell>
                <TableCell className="data-cell">{list.processId}</TableCell>
                <TableCell className="data-cell">{list.timestamp}</TableCell>
                <TableCell className="data-cell">{list.startTime}</TableCell>
                <TableCell className="data-cell">{list.endTime}</TableCell>
                <TableCell className="data-cell">
                  <IconButton onClick={() => handleViewImage(list.image)}>
                    {open ? <MdOutlineVisibility/> : <MdOutlineVisibilityOff/>}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={alertList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>View Image</DialogTitle>
        <DialogContent>
          {selectedImage ? (
            <img src={`data:image/png;base64,${selectedImage}`} alt="Alert" style={{ width: '100%' }} />
          ) : (
            <p>No Image Available</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AllAlerts;
