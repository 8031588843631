import React, { useContext, useState } from "react";
import { FaRegFolderOpen } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import Loader from "../../Components/Utils/Loader"; // Import the Loader component
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../ContextApi/MyContext";
import axios from "axios";
import api from "../../Components/Utils/api";

function Create() {
  const [images, setImages] = useState([]);
  const [labels, setLabels] = useState([]);
  const [labelDataToSend, setLabelDataToSend] = useState([]);
  const [labelValue, setLabelValue] = useState("");
  const [modelName, setModelName] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [errors, setErrors] = useState([]); // Error state
  const clientData = JSON.parse(localStorage.getItem("client"));
  const clientName = clientData ? clientData.clientName : null;
  const { SuccessAlert } = useContext(UserContext);
  const location = useLocation();

  const projectNameFiles = location.state;
  const projectNameFile =
    projectNameFiles.projectName + projectNameFiles.projectId;
  const navigate = useNavigate();
  const handleImageFileSelect = async (event) => {
    setImages(event.target.files);
  };

  const handleLabelFileSelect = async (event) => {
    setLabels(event.target.files);
  };

  const validateForm = () => {
    let isValid = true;
    let errors = [];

    if (!modelName) {
      isValid = false;
      errors.push("Model name is required.");
    }

    if (images.length === 0) {
      isValid = false;
      errors.push("Please select at least 300 image.");
    }

    if (labels.length === 0) {
      isValid = false;
      errors.push("Please select at least 300 label.");
    }

    if (labelDataToSend.length === 0) {
      isValid = false;
      errors.push("Class name is required.");
    }

    return { isValid, errors };
  };

  const submitDetails = async () => {
    const { isValid, errors } = validateForm();
    if (!isValid) {
      setErrors(errors);
      return;
    }
    try {
      setIsLoading(true); // Start loading
      const formData = new FormData();
      for (let i = 0; i < images.length; i++) {
        formData.append(`uploaded_images`, images[i]);
      }
      for (let i = 0; i < labels.length; i++) {
        formData.append(`uploaded_labels`, labels[i]);
      }
      const valueToSend = labelDataToSend.join(",");
      formData.append("class_name", valueToSend);
      formData.append("model_name", modelName);
      formData.append("projectName", projectNameFile);
      formData.append("client_name", clientName);
      formData.append("operation", "start");
      // Print FormData contents
      Array.from(formData.entries()).forEach(([key, value]) => {
        console.log(`${key}: ${value}`);
      });

      const response = await api.post(
        `/webhook/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const responseData = response.data;
      SuccessAlert(responseData);
      if (response.status === 201) {
        const responseData = response.data;
        SuccessAlert(responseData);
        navigate("../annotationandlabelling");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error", error.response?.data);
      } else {
        console.error("Error", error.message);
      }
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setLabelValue(value);
    const array = value.split(",").map((item) => item.trim());
    setLabelDataToSend(array);
  };

  const handleModelNameChange = (e) => {
    const value = e.target.value;
    setModelName(value);
  };

  return (
    <div className="anotation">
      {isLoading && <Loader />} {/* Conditionally render the loader */}
      <div className="anotation__container">
        <FaRegFolderOpen size={"20rem"} color={"#3d3d3d"} />

        {/* Display error messages */}
        {errors.length > 0 && (
          <div className="error-messages">
            {errors.map((error, index) => (
              <div key={index} className="error">
                {error}
              </div>
            ))}
          </div>
        )}

        <div className="anotation__input">
          <div className="anotation__box">
            <label htmlFor="notationInputFile">Choose folder for images:</label>
            <input
              type="file"
              className="notationInputFile"
              directory=""
              name="notationInputFile"
              webkitdirectory=""
              onChange={(event) => handleImageFileSelect(event)}
              multiple
              style={{
                border: "2px solid #ccc",
                borderRadius: "4px",
                padding: "8px 12px",
                marginRight: "100px",
                fontSize: "16px",
                width: "200px",
                outline: "none",
                cursor: "pointer",
              }}
            />
          </div>
          <div className="anotation__box">
            <label htmlFor="notationInputFile">Choose folder for Labels:</label>
            <input
              type="file"
              className="notationInputFile"
              directory=""
              name="notationInputFile"
              webkitdirectory=""
              onChange={(event) => handleLabelFileSelect(event)}
              multiple
              style={{}}
            />
          </div>
        </div>

        <div className="anotation__input">
          <div className="ann__inputbox">
            <label>Enter class names:</label>
            <input
              type="text"
              placeholder="eg. glass, mobile"
              className="notationInputFile"
              value={labelValue}
              onChange={handleInputChange}
            />
          </div>
          <div className="ann__inputbox">
            <label>Enter model name: </label>
            <input
              type="text"
              placeholder="Enter Model name"
              className="notationInputFile"
              value={modelName}
              onChange={handleModelNameChange}
            />
          </div>
        </div>
        <button onClick={() => submitDetails()} className="anotation__submit">
          Submit
        </button>
      </div>
    </div>
  );
}

export default Create;
