import React from 'react'

const ServiceCard = ({item}) => {
  return (
    <div key={item.id} className='service_card, div2__Content-TeamWork'>
      <img className='service_card__img' src={item.image} ></img>
    </div>
  )
}

export default ServiceCard