
import { useContext, useEffect, useMemo, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "../../Assets/Styles/table.css";
import { UserContext } from "../../ContextApi/MyContext";
import ReactTable from "../Utils/MterialUiTable";


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const LocalisationProvider = () => {
  const { theme, GetDeviceData } = useContext(UserContext);
  const currentTheme = theme === "dark" ? darkTheme : lightTheme;
  useEffect(()=>{
    GetDeviceData()
  }, [])

  return (
    <ThemeProvider theme={currentTheme} >
    <LocalizationProvider dateAdapter={AdapterDayjs} >
      <ReactTable  />
    </LocalizationProvider>
  </ThemeProvider>
  );
};

export default LocalisationProvider;
