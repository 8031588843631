import { useState, useCallback } from "react";

const useImageAnnotations = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [annotationsByImage, setAnnotationsByImage] = useState({});

  const handleAnnotationsChange = useCallback((newAnnotations) => {
    setAnnotationsByImage((prevAnnotations) => ({
      ...prevAnnotations,
      [currentIndex]: newAnnotations,
    }));
  }, [currentIndex]);

  const getAnnotationsData = () => {
    return images.map((image, index) => {
      // Determine the image format
      const formatMatch = image.src.match(/^data:image\/(png|jpg|jpeg);base64,/);
      let format = 'png'; // Default format
      let base64Data = image.src;
  
      if (formatMatch) {
        format = formatMatch[1];
        base64Data = image.src.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
      }
  
      return {
        src: base64Data,
        format: format,
        annotations: annotationsByImage[index] || [],
      };
    });
  };

  return {
    currentIndex,
    setCurrentIndex,
    annotationsByImage,
    handleAnnotationsChange,
    images,
    setImages,
    getAnnotationsData,
  };
};

export default useImageAnnotations;
