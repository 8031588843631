import React, { useContext, useEffect, useState } from "react";
import Draggable from "react-draggable";
import "./ModelNotificationCard.css"; // Optional: for custom styling
import { MdClose } from "react-icons/md";
import { FaPlay, FaRedo, FaStop } from "react-icons/fa";
import { MdCancelScheduleSend } from "react-icons/md";
import { UserContext } from "../../ContextApi/MyContext";
import api from "../Utils/api";

const ModelNotificationCard = ({ title }) => {
  const [trainingStatus, setTrainingStatus] = useState({});
  const [isTraining, setIsTraining] = useState();
  const [cardVisible, setCardVisible] = useState(false);
  const [trainedData, setTrainedData] = useState();
  const { clientData } = useContext(UserContext);

  const clientName = clientData ? clientData.clientName : null;

  const handleStartStop = async (status, project_id) => {
    const modeldata = trainedData.find((item)=>item.project_id === project_id)
    console.log(modeldata)
    const newTrainingStatus = { ...trainingStatus };
    if (status === "restart") {
      try {
        await api.post(
          `/api/annotation/${clientName}/changetrainingstatus`,
          {
            project_name: modeldata.project_name,
            client_name: clientName,
            model_name: modeldata.modelName,
            operation: "restart",
            pythonApi: process.env.REACT_APP_MODEL_TRAIN_API_BASE_URL,
            project_id:project_id
          }
        );
        newTrainingStatus[project_id] = true;
      } catch (error) {
        console.error("Error starting training:", error.message);
      }
    } else if (status === "stop") {
      try {
        await api.post(`/api/annotation/${clientName}/changetrainingstatus`,
          {
            project_name: modeldata.project_name,
            client_name: clientName,
            model_name: modeldata.modelName,
            operation: "stop",
            pythonApi: process.env.REACT_APP_MODEL_TRAIN_API_BASE_URL,
            project_id:project_id
          }
        );
        newTrainingStatus[project_id] = false;
      } catch (error) {
        console.error("Error stopping training:", error.message);
      }
    }
    else if (status === "cancel") {
      try {
        await api.post(
          `/api/annotation/${clientName}/changetrainingstatus`,
          {
            project_name: modeldata.project_name,
            client_name: clientName,
            model_name: modeldata.modelName,
            operation: "cancel",
            pythonApi: process.env.REACT_APP_MODEL_TRAIN_API_BASE_URL,
            project_id:project_id
          }
        );
        newTrainingStatus[project_id] = false;
      } catch (error) {
        console.error("Error stopping training:", error.message);
      }
    }
    setTrainingStatus(newTrainingStatus);
  };
  const handleCardVisibleOpen = () => {
    setCardVisible(true);
  };
  const handleCardVisibleClose = () => {
    setCardVisible(false);
    
  };
  useEffect(() => {
    const GetTrainModelData = async () => {
      try {
        // Make GET request using the common API utility
        const response = await api.get(`/api/annotation/${clientName}/getTrainModelData`);
    
        const data = response.data;
        const traindata = data.filter(item => item.operation === "start" || item.operation === "stop");
        console.log("Data:", traindata);
    
        const initialStatus = {};
        traindata.forEach(item => {
          initialStatus[item.project_id] = item.operation === "start" ? true : false;
        });
        setTrainingStatus(initialStatus);
        console.log("ddd", initialStatus);
        setTrainedData(traindata);
      } catch (error) {
        console.error("Error Fetching Train Model Data:", error.message);
        if (error.response) {
          // handleCardVisibleClose(); // Uncomment if needed
          console.log("Status:", error.response.status);
          console.log("Response Data:", error.response.data);
        } else {
          console.log("An error occurred while fetching data.");
        }
      }
    };

    GetTrainModelData();
  }, [clientName]);

  return (
    <>
      {cardVisible && (
        <Draggable bounds="parent">
          <div className="modelTraining-card">
            <div className="card-header">
              <h4>{title}</h4>
              <MdClose className="close-icon" onClick={handleCardVisibleClose} />
            </div>

            {/* Scrollable Div */}
            <div className="scrollable-content">
              {trainedData?.map((item) => (
                <React.Fragment key={item.project_id}>
                  <div className="card-body">
                    {trainingStatus[item.project_id] ? (
                      <div className="training-animation">
                        <div className="spinner"></div>
                        <p>Training is starting...</p>
                      </div>
                    ) : (
                      <p>Training is stopped.</p>
                    )}


                  <div className="card-footer">
                  <div>
                    <h1> Project Name: {item.project_name}</h1>
                    <h3>Model Name: {item.modelName}</h3>
                  </div>
                    {!trainingStatus[item.project_id] ? (
                      <button onClick={()=>handleStartStop('restart', item.project_id)}>
                      <FaRedo title="Restart" />
                    </button>
                    ) : (
                      <button onClick={()=>handleStartStop("stop", item.project_id)}>
                        <FaStop title="Stop" />
                      </button>
                    )}
                   
                    <button onClick={()=>handleStartStop("cancel", item.project_id)}>
                      <MdCancelScheduleSend title="Cancel Training" />
                    </button>
                  </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </Draggable>
      )}
    </>
  );
};

export default ModelNotificationCard;
