function useFeatureExists(features) {
  const checkFeatureExists = (featureName) => {
    return features.some((feature) => feature.featureName === featureName);
  };

  return {
    checkFeatureExists,
  };
}

export default useFeatureExists;
