// src/api.js
import axios from "axios";

// Create an axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to get the token from local storage
const getToken = () => localStorage.getItem("token");
// Set the token in the headers for every request
api.interceptors.request.use(
  (config) => {

       // Check if the URL includes "webhook"
       if (config.url && config.url.includes("webhook")) {
        // Change baseURL if it's a webhook request
        config.baseURL = process.env.REACT_APP_MODEL_TRAIN_API_BASE_URL; // Use the Python API base URL
      } else {
        // Otherwise, use the default baseURL
        config.baseURL = process.env.REACT_APP_API_BASE_URL;
      }
  
      // Add the token to the header if not a webhook request
      if (config.url && !config.url.includes("webhook")) {
        const token = getToken();
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
      }
    return config;
  },
  (error) => Promise.reject(error)
);

// Functions to make API requests
export const get = (url, config = {}) => api.get(url, config);
export const post = (url, data, config = {}) => api.post(url, data, config);
export const put = (url, data, config = {}) => api.put(url, data, config);
export const deleteRequest = (url, config = {}) => api.delete(url, config);

// Export the axios instance directly if needed
export default api;
