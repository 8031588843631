import React, { useContext, useEffect, useRef, useState } from "react";
import "../../Assets/Styles/cameraComponent.css";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../ContextApi/MyContext";
import ConfigSkeleton from "../LoaderSkeleton/ConfigSkeleton";
import api from "../Utils/api";

const InoutComponent = ({
  deviceName,
  startRTSPFeed,
  stopRTSPFeed,
  streamDataUrl,
  closeMultiStreams,
  setRectangles,
  rectangles,
}) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [currentRectangle, setCurrentRectangle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lineColor, setLineColor] = useState("green");
  const regex = /^\/dashboard\/features\/(anpr|smartbox)\/\w+/;
  const location = useLocation();
  const { SuccessAlert, ErrorAlert, isSkeletonLoading } =
    useContext(UserContext);
  const [pathName, setPathName] = useState("");
  const pythonApiUrl = process.env.REACT_APP_MODEL_TRAIN_API_BASE_URL;

  useEffect(() => {
    setPathName(location.pathname);

    return () => {
      closeMultiStreams();
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error("Canvas not found");
      // Render an error message or take other action in your UI
      return;
    }

    const context = canvas.getContext("2d");
    if (!context) {
      console.error("2D context not found");
      // Render an error message or take other action in your UI
      return;
    }

    try {
      // Clear the canvas
      context.clearRect(0, 0, canvas.width, canvas.height);

      // Redraw all rectangles
      rectangles.forEach((rectangle) => {
        const { startX, startY, endX, endY, color } = rectangle;
        context.strokeStyle = color;
        context.lineWidth = 2;
        context.strokeRect(startX, startY, endX - startX, endY - startY);
      });

      // Draw the current rectangle
      if (currentRectangle) {
        const { startX, startY, endX, endY, color } = currentRectangle;
        context.strokeStyle = color;
        context.lineWidth = 2;
        context.strokeRect(startX, startY, endX - startX, endY - startY);
      }
    } catch (error) {
      console.error("Error drawing on canvas:", error);
      // Render an error message or take other action in your UI
    }
  }, [rectangles, currentRectangle]);

  const handleMouseDown = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const startX = e.clientX - rect.left;
    const startY = e.clientY - rect.top;
    setCurrentRectangle({
      startX,
      startY,
      endX: startX,
      endY: startY,
      color: lineColor,
    });
    setIsDrawing(true);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const endX = e.clientX - rect.left;
    const endY = e.clientY - rect.top;
    setCurrentRectangle((prevRectangle) => ({
      ...prevRectangle,
      endX,
      endY,
    }));
  };

  const handleMouseUp = () => {
    if (currentRectangle) {
      // Adjust the coordinates to ensure endX and endY are the bottom-right corner of the rectangle
      const { startX, startY, endX, endY } = currentRectangle;
      const rectangle = {
        startX: Math.min(startX, endX),
        startY: Math.min(startY, endY),
        endX: Math.max(startX, endX),
        endY: Math.max(startY, endY),
        // color: rectangles.length === 0 ? "green" : "yellow",
        color: lineColor
      };

      // if (rectangles.length < 0) {
      //   setRectangles((prevRectangles) => [...prevRectangles, rectangle]);
      // } else {
        // Only allow two rectangles, replace the first one if two have been drawn
        // setRectangles((prevRectangles) => [prevRectangles[1], rectangle]);
        setRectangles([rectangle]);
      // }
    }

    setCurrentRectangle(null);
    setIsDrawing(false);
  };

  function formatData(rectangles) {
    const formattedData = {};
    rectangles.forEach((rectangle, index) => {
      formattedData[
        `ay${index + 1},cy${index + 1},by${index + 1},dy${index + 1}`
      ] = [rectangle.startX, rectangle.startY, rectangle.endX, rectangle.endY];
    });
    return formattedData;
  }

  function boundingBoxApi() {
    if (rectangles.length === 0) {
      console.error("No rectangles to submit");
      return;
    }
    const rectanglesData = formatData(rectangles);

    api
      .post(`/webhook/line`, {
        url: streamDataUrl,
        lines: rectanglesData,
      })
      .then((response) => {
        SuccessAlert(response.data.message);
      })
      .catch((error) => {
        ErrorAlert(error.response.data.error);
      });
  }

  // Function to clear all drawn rectangles
  const clearCanvas = () => {
    setRectangles([]);
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
  };
  return (
    <>
      {isSkeletonLoading ? (
        <ConfigSkeleton
          isSkeletonLoading={isLoading}
          pathName={pathName}
          regex={regex}
        />
      ) : (
        <div className="configStream ">
          <h1>{deviceName}</h1>
          {regex.test(pathName) ? (
            <div className="canvas-container1">
              <canvas
                className="canvas"
                id="video-canvas"
                width="900px"
                height="600px"
                style={{ zIndex: 1 }}
              ></canvas>
              <canvas
                id="video"
                className="canvas"
                width="900px"
                height="600px"
                style={{ zIndex: 2 }}
                ref={canvasRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
              ></canvas>
            </div>
          ) : (
            <canvas id="video-canvas"></canvas>
          )}
          <div style={{ paddingLeft: "1rem" }}>
            <button className="configStream__btn" onClick={startRTSPFeed}>
              Start Stream
            </button>
            <button
              className="configStream__btn"
              onClick={() => stopRTSPFeed("stop")}
            >
              Stop Stream
            </button>
            {regex.test(pathName) ? (
              <button className="configStream__btn" onClick={clearCanvas}>
                Clear
              </button>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};
export default InoutComponent;
