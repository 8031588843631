import React, { useContext, useEffect, useRef, useState } from "react";
import { Stage, Layer, Rect, Text, Image as KonvaImage } from "react-konva";
import useImage from "use-image";
import { v4 as uuidv4 } from "uuid";
import { UserContext } from "../../ContextApi/MyContext";
import { TfiBackLeft } from "react-icons/tfi";
import { GrNext, GrPrevious } from "react-icons/gr";
import { MdOutlineFolderDelete } from "react-icons/md";

const CANVAS_WIDTH = 640;
const CANVAS_HEIGHT = 640;

function ImageCanvas({
  imageSrc,
  handleClearImages,
  annotations,
  setAnnotations,
  currentIndex,
  setCurrentIndex,
  label,
  setLabel,
  images,
  newAnnotation,
  setNewAnnotation,
  isLabelSet,
  setIsLabelSet, // New setter to manage label state
  handleLabelChange,
}) {
  const [isDrawing, setIsDrawing] = useState(false);
  const stageRef = useRef(null);
  const [image] = useImage(imageSrc);
  const { ErrorAlert } = useContext(UserContext);

  useEffect(() => {
    if (image) {
      handleDrawImage();
    } else {
      setNewAnnotation([]);
    }
  }, [image]);

  const handleDrawImage = () => {
    const stage = stageRef.current.getStage();
    const layer = stage.getLayers()[0];
    const konvaImage = layer.findOne("Image");
    if (konvaImage) {
      konvaImage.width(CANVAS_WIDTH);
      konvaImage.height(CANVAS_HEIGHT);
      konvaImage.getLayer().batchDraw();
    }
  };

  const handleMouseDown = (e) => {
    if (!image) {
      ErrorAlert("Please upload an image first.");
      return;
    }
    if (!isLabelSet) {
      ErrorAlert("Please set a label before drawing a box.");
      return;
    }
    if (!isDrawing) {
      const stage = e.target.getStage();
      const point = stage.getPointerPosition();
      setNewAnnotation([point.x, point.y, 0, 0]);
      setIsDrawing(true);
    }
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;

    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    const sx = newAnnotation[0];
    const sy = newAnnotation[1];
    const width = point.x - sx;
    const height = point.y - sy;

    setNewAnnotation([sx, sy, width, height]);
  };

  const handleMouseUp = () => {
    if (isDrawing) {
      const yoloAnnotation = convertToYOLO(newAnnotation);
      const updatedAnnotations = [
        ...annotations,
        { id: uuidv4(), ...yoloAnnotation, label },
      ];
      setAnnotations(updatedAnnotations);
      setNewAnnotation([]);
      setIsDrawing(false);
      setLabel(""); // Reset label after adding annotation
      setIsLabelSet(false); // Reset isLabelSet after adding annotation
    }
  };

  const handleRemoveAnnotation = () => {
    setAnnotations(annotations.slice(0, -1));
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleClick = (event) => {
    const x = event.evt.clientX - stageRef.current.attrs.container.offsetLeft;
    const y = event.evt.clientY - stageRef.current.attrs.container.offsetTop;

    const indexToRemove = annotations.findIndex((annotation) => {
      return (
        Math.abs(annotation.x_center * CANVAS_WIDTH - x) <= 10 &&
        Math.abs(annotation.y_center * CANVAS_HEIGHT - y) <= 10
      );
    });

    if (indexToRemove !== -1) {
      const updatedAnnotations = annotations.filter(
        (_, index) => index !== indexToRemove
      );
      setAnnotations(updatedAnnotations);
    }
  };

  const convertToYOLO = (annotation) => {
    const [x, y, width, height] = annotation;
    var x_center = ((x + width / 2) / CANVAS_WIDTH).toFixed(4);
    var y_center = ((y + height / 2) / CANVAS_HEIGHT).toFixed(4);
    var normalized_width = (width / CANVAS_WIDTH).toFixed(4);
    var normalized_height = (height / CANVAS_HEIGHT).toFixed(4);

    // Convert back to numbers since .toFixed() returns a string
    x_center = parseFloat(x_center);
    y_center = parseFloat(y_center);
    normalized_width = parseFloat(normalized_width);
    normalized_height = parseFloat(normalized_height);

    return {
      x_center,
      y_center,
      width: normalized_width,
      height: normalized_height,
    };
  };

  return (
    <div className="imageCanva">
      <div className="imageCanva__controls">
        <button onClick={handleClearImages}>
          <MdOutlineFolderDelete className="imageCanva__Clear" size={22} />
        </button>
        <button
          onClick={handleRemoveAnnotation}
          disabled={annotations.length === 0}
        >
          <TfiBackLeft
            className={
              annotations.length === 0 ? "imageCanva__btn" : "imageCanva__btnback"
            }
            size={22}
          />
        </button>
        <button onClick={handlePrev} disabled={currentIndex === 0}>
          <GrPrevious size={22} />
        </button>
        <div className="count">{`${currentIndex + 1}/${images.length}`}</div>
        <button
          onClick={handleNext}
          disabled={currentIndex === images.length - 1}
        >
          <GrNext size={22} />
        </button>
            
      </div>

      <Stage
        width={CANVAS_WIDTH}
        height={CANVAS_HEIGHT}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onClick={handleClick}
        ref={stageRef}
      >
        <Layer>
          {image && <KonvaImage image={image} />}
          {annotations.map((annotation, index) => (
            <Rect
              key={annotation.id}
              x={
                annotation.x_center * CANVAS_WIDTH -
                (annotation.width * CANVAS_WIDTH) / 2
              }
              y={
                annotation.y_center * CANVAS_HEIGHT -
                (annotation.height * CANVAS_HEIGHT) / 2
              }
              width={annotation.width * CANVAS_WIDTH}
              height={annotation.height * CANVAS_HEIGHT}
              stroke="red"
              strokeWidth={2}
              listening={false}
            />
          ))}
          {newAnnotation.length === 4 && (
            <Rect
              x={newAnnotation[0]}
              y={newAnnotation[1]}
              width={newAnnotation[2]}
              height={newAnnotation[3]}
              stroke="green"
              strokeWidth={2}
              listening={false}
            />
          )}
        </Layer>
      </Stage>
    </div>
  );
}

export default ImageCanvas;
