import React, { useState, useEffect } from "react";
import "../../Assets/Styles/dashboard.css";
import Navbar from "../../Components/Layout/Sidebar/Sidebar";
import AdminSidebar from "../../Components/Layout/Sidebar/AdminSidebar";
import { Outlet } from "react-router-dom";
import Header from "../../Components/Layout/Header";
import SkeletonLoader from "../../Components/LoaderSkeleton/SkeletonLoader"; // Import the skeleton loader
import TabBarNav from "../../Components/Layout/TabBar";

const Dashboard = () => {
  const [sidebarActive, setSidebarActive] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [clientRole, setClientRole] = useState(localStorage.getItem("role"));
  
  const toggleSidebar = () => {
    setSidebarActive(!sidebarActive);
  };

  // Simulate data fetching or processing
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Stop loading after 2 seconds
    }, 2000); // 2000 ms = 2 seconds
    
    return () => {
      clearTimeout(timer); // Clear the timeout when the component unmounts
    };
  }, []);

  // Monitor role changes and update state
 
  const renderSidebar = () => {
    if (clientRole === "admin") {
      console.log("Addmin")
      return <AdminSidebar clientRole={clientRole}/>;
    } else {
      console.log("Adn")
      return <Navbar clientRole={clientRole}/>;
    }
  };

  return (
    <div className="main-dashboard">
      {loading ? (
        <SkeletonLoader />
      ) : (
        <div className={`dashboard ${sidebarActive ? "sidebar active" : ""}`}>
          <div className="dashboard-header">
            <Header toggleSidebar={toggleSidebar}/>
          </div>
          <div className="dashboard-content">
            <div className="navbar-container">
              {renderSidebar()}
            </div>
            <div className="MainContent">
              
              <Outlet  context={{clientRole}} />
              <TabBarNav/>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
