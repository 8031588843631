import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../../Assets/Styles/HomeNavBar.css";
import logo from "../../Assets/Images/AiLogo.png";
import { FaBars, FaTimes } from "react-icons/fa";
import CtaButton from "../Utils/CtaButton";

const HomeNavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleRouteLogin = () => {
    navigate("/login");
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".homenavbar");
      if (window.scrollY > 50) {
        navbar.classList.add("li");
      } else {
        navbar.classList.remove("li");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="homenavbar">
      <NavLink to="/" className="homenavbar__link homelink">
        <img src={logo} alt="Logo" className="logo" />
      </NavLink>
      <div
        className={`homenavbar__link_con ${isMobileMenuOpen ? "active" : ""}`}
      >
        <NavLink
          to="/"
          className="homenavbar__link homelink"
          activeclassname="active"
          onClick={toggleMobileMenu} // Close menu on link click
        >
          Home
        </NavLink>
        <NavLink
          to="/aboutus"
          className="homenavbar__link"
          activeclassname="active"
          onClick={toggleMobileMenu}
        >
          About
        </NavLink>
        <NavLink
          to="/ourservice"
          className="homenavbar__link"
          activeclassname="active"
          onClick={toggleMobileMenu}
        >
          Our Services
        </NavLink>
        <NavLink
          to="/product"
          className="homenavbar__link"
          activeclassname="active"
          onClick={toggleMobileMenu}
        >
          Product
        </NavLink>
        <NavLink
          to="/whybuyaisolution"
          className="homenavbar__link"
          activeclassname="active"
          onClick={toggleMobileMenu}
        >
          Why BuyAISolution?
        </NavLink>
        <NavLink
          to="/login"
          className="homenavbar__link navlogin"
          activeclassname="active"
          onClick={toggleMobileMenu}
        >
          Login
        </NavLink>
      </div>
      <div className="homenavbar__right">
        <CtaButton
          text="Login"
          handleRouteLogin={handleRouteLogin}
          className="cta"
        />
        <button className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>
    </div>
  );
};

export default HomeNavBar;
