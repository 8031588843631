import React, { useContext, useRef, useState } from "react";
import { RiLogoutBoxRFill } from "react-icons/ri";
import { IoIosContact } from "react-icons/io";
import "../Assets/Styles/profile.css";
import { UserContext } from "../ContextApi/MyContext";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { IoMdArrowDropdown } from "react-icons/io";

const Profile = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const clientData = JSON.parse(localStorage.getItem("client"));
  const clientName = clientData ? clientData.username : null;
  const { toggleTheme, theme } = useContext(UserContext);
  const subtitleRef = useRef(null);
  const modalClassName =
    theme === "dark" ? "add-table dark" : "add-table light";

  const navigate = useNavigate();

  const confirmLogout = () => {
    const isConfirmed = window.confirm("Are you sure you want to log out?");
    if (isConfirmed) {
      localStorage.clear();
      navigate("/login");
    console.log("Logged out successfully.");
    } else {
      console.log("Logout cancelled.");
    }
  };

  const ProfileToggale = () => {
    setIsOpen(!modalIsOpen);
  }; 
  function afterOpenModal() {
    if (subtitleRef.current) {
      subtitleRef.current.style.backgroundColor = "red"; // Example style change
    }
  }

  return (
    <div className="profile-con">
      <button onClick={ProfileToggale} className="profile">
        <IoIosContact className="profile-icon" />
        <IoMdArrowDropdown />
      </button>
      <Modal
        isOpen={modalIsOpen}
        className={"profile__model"}
        onAfterOpen={afterOpenModal}
        overlayClassName="modal__bg"
        onRequestClose={ProfileToggale}
        ariaHideApp={false}
        style={{
          content: {
            backdropFilter: "saturate(180%) blur(50px)",
          },
        }}
      >
        <div className={`profile_modal ${modalClassName}`}>
          <ul className="modal-content">
            <li className="userName_profile">
              <IoIosContact size={60} className="profile-icon" />  <li className="userName">{clientName}</li>
            </li>
            <div className="min-border"></div>
            <li className="account_title">Account</li>
            <li className="userEmail">Email : {clientData?.email}</li>
            <li>
              <button onClick={() => toggleTheme()}>
                <div className ="toggle">
                  Theme
                  <input type="checkbox" />
                  <label></label>
                </div>
              </button>
            </li>
            <li>
              <button onClick={confirmLogout}>
                <RiLogoutBoxRFill />
                Logout
              </button>
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default Profile;
