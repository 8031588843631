import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../ContextApi/MyContext';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import { MdExpandMore, MdOutlineVisibility } from "react-icons/md";
import { MdOutlineVisibilityOff } from "react-icons/md";
import { FaEye, FaEyeSlash, FaInfoCircle } from 'react-icons/fa';

const MatchedPeople = () => {
    const {matchedPeople, GetMatchedPeople,GetPeopleData,uniquePeople, theme}=useContext(UserContext);
    const [page, setPage] = useState(0);
    const [page1, setPage1] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rowsPerPage1, setRowsPerPage1] = useState(5);
    const [openAlertImage, setOpenAlertImage] = useState(false);
    const [alertImage, setAlertImage] = useState(null);
    const [openViewList, setOpenViewList] = useState(null);

    const modalClassName =
    theme === "dark"
      ? "smsAlert__container dark-theme"
      : "smsAlert__container light-theme";

      useEffect(() => {
        GetMatchedPeople();
        console.log('matchedPeople', matchedPeople);
    }, []);


    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangePage1 = (event, newPage) => {
        setPage1(newPage);
      };

    const isEmpty = (obj) => {
        return !obj || Object.keys(obj).length === 0;
      };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleChangeRowsPerPage1 = (event) => {
        setRowsPerPage1(parseInt(event.target.value, 10));
        setPage1(0);
      };

    if (isEmpty(matchedPeople)) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            NO DATA
        </div>
    };
    const handleView = (unique_id) => {
        setOpenViewList(true);
        GetPeopleData(unique_id);
    }
    const handleViewImage = (image) => {
        setAlertImage(image);
        setOpenAlertImage(true);
      };
      const handleViewClose = () =>{
        setOpenViewList(false);
      }
      const handleClose = () => {
        setOpenAlertImage(false);
        setAlertImage(null);
      };


    const emptyRows = rowsPerPage - Math.min(rowsPerPage, matchedPeople.length - page * rowsPerPage);
    const emptyRows1 = rowsPerPage1 - Math.min(rowsPerPage1, uniquePeople.length - page1 * rowsPerPage1);

  return (
    <div className={modalClassName}>
        <h2 style={{margin:"1rem 0"}}>People Counts</h2>  
        <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className="sms__tabler-row">
              <TableCell className="sms__tblecell">Sr.no</TableCell>
              <TableCell className="sms__tblecell">Unique Id</TableCell>
              <TableCell className="sms__tblecell">Count</TableCell>
              <TableCell className="sms__tblecell">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matchedPeople.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((list, index) => (
              <TableRow key={list.id}>
                <TableCell className="data-cell">{page * rowsPerPage + index + 1}</TableCell>
                <TableCell className="data-cell">{list.unique_id}</TableCell>
                <TableCell className="data-cell">{list.count}</TableCell>
                <TableCell className="data-cell">
                  <IconButton onClick={() => handleView(list.unique_id)} >
                    <MdExpandMore />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={matchedPeople.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />  

      <Dialog open={openViewList} onClose={handleViewClose} maxWidth="md" fullWidth>
        <DialogTitle>View People List</DialogTitle>
        <DialogContent>
        <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className="sms__tabler-row">
              <TableCell className="sms__tblecell">Sr.no</TableCell>
              <TableCell className="sms__tblecell">Unique Id</TableCell>
              <TableCell className="sms__tblecell">Gate</TableCell>
              <TableCell className="sms__tblecell">Device Name</TableCell>
              <TableCell className="sms__tblecell">Date time</TableCell>
              {/* <TableCell className="sms__tblecell">image</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {uniquePeople.slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1).map((list, index) => (
              <TableRow key={list.id}>
                <TableCell className="data-cell">{page1 * rowsPerPage1 + index + 1}</TableCell>
                <TableCell className="data-cell">{list.unique_id}</TableCell>
                <TableCell className="data-cell">{list.gate_type}</TableCell>
                <TableCell className="data-cell">{list.device_name}</TableCell>
                <TableCell className="data-cell">{list.timestamp}</TableCell>
                {/* <TableCell className="data-cell">
                  <IconButton onClick={() => handleViewImage(list.image)}>
                    {openAlertImage ? <MdOutlineVisibility/> : <MdOutlineVisibilityOff/>}
                  </IconButton>
                </TableCell> */}
              </TableRow>
            ))}
            {emptyRows1 > 0 && (
              <TableRow style={{ height: 53 * emptyRows1 }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        </TableContainer>
        <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={uniquePeople.length}
        rowsPerPage={rowsPerPage1}
        page={page1}
        onPageChange={handleChangePage1}
        onRowsPerPageChange={handleChangeRowsPerPage1}
      />  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default MatchedPeople;
